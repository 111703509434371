import { 
    WEB_MARKETING_ADD_ONS_SECTION,
    WEB_MARKETING_PROMO_BANNER_US, 
    WEB_MARKETING_FANDUEL_PLAN_CARDS, 
    WEB_MARKETING_FAQ_MESSAGING, 
    WEB_MARKETING_INCLUDE_CRTC, 
    WEB_MARKETING_MATCHTICKER_PLACEMENT_V_NONE, 
    WEB_MARKETING_PROMO_BADGE,
    WEB_MARKETING_CTA_COPY_CONTINUE,
    WEB_MARKETING_VALUE_PROPS_HERO
  } from './constants'

import type { BooleanValueFlag, Flag, StringValueFlag } from './types'

type MarketingFAQMessaging = typeof WEB_MARKETING_FAQ_MESSAGING
type MarketingFanduelPlanCards = typeof WEB_MARKETING_FANDUEL_PLAN_CARDS
type MarketingAddOnsSection = typeof WEB_MARKETING_ADD_ONS_SECTION
type MarketingPromoBannerUS = typeof WEB_MARKETING_PROMO_BANNER_US
type MarketingIncludeCRTC = typeof WEB_MARKETING_INCLUDE_CRTC
type MarketingMatchTickerPlacementvNone = typeof WEB_MARKETING_MATCHTICKER_PLACEMENT_V_NONE 
type MarketingPromoBadge = typeof WEB_MARKETING_PROMO_BADGE 
type MarketingCtaCopyContinue = typeof WEB_MARKETING_CTA_COPY_CONTINUE  
type MarketingValuePropsHero = typeof WEB_MARKETING_VALUE_PROPS_HERO

function flag<K extends string, V>(key: K, value: V, ttl = -1): Flag<K, V> {
  return {
    key,
    value,
    ttl,
  }
}
function booleanFlag<K extends string>(
  key: K,
  value = false,
  ttl = -1
): BooleanValueFlag<K> {
  return flag<K, boolean>(key, value, ttl)
}
function stringFlag<K extends string>(
  key: K,
  value = 'default',
  ttl = -1
): StringValueFlag<K> {
  return flag<K, string>(key, value, ttl)
}

export const initValues = {
  [WEB_MARKETING_FAQ_MESSAGING]: stringFlag<MarketingFAQMessaging>(WEB_MARKETING_FAQ_MESSAGING),
  [WEB_MARKETING_FANDUEL_PLAN_CARDS]: stringFlag<MarketingFanduelPlanCards>(WEB_MARKETING_FANDUEL_PLAN_CARDS),
  [WEB_MARKETING_ADD_ONS_SECTION]: stringFlag<MarketingAddOnsSection>(WEB_MARKETING_ADD_ONS_SECTION),  
  [WEB_MARKETING_PROMO_BANNER_US]: stringFlag<MarketingPromoBannerUS>(WEB_MARKETING_PROMO_BANNER_US),
  [WEB_MARKETING_INCLUDE_CRTC]: stringFlag<MarketingIncludeCRTC>(WEB_MARKETING_INCLUDE_CRTC),
  [WEB_MARKETING_MATCHTICKER_PLACEMENT_V_NONE]: stringFlag<MarketingMatchTickerPlacementvNone>(WEB_MARKETING_MATCHTICKER_PLACEMENT_V_NONE),
  [WEB_MARKETING_PROMO_BADGE]: stringFlag<MarketingPromoBadge>(WEB_MARKETING_PROMO_BADGE),
  [WEB_MARKETING_CTA_COPY_CONTINUE]: stringFlag<MarketingCtaCopyContinue>(WEB_MARKETING_CTA_COPY_CONTINUE),
  [WEB_MARKETING_VALUE_PROPS_HERO]: stringFlag<MarketingValuePropsHero>(WEB_MARKETING_VALUE_PROPS_HERO),
} as const

export type StacFlags = typeof initValues
export type StacFlagKey = keyof StacFlags
export type StacFlagKeys = StacFlagKey[]

export const supportedFlags = Object.keys(initValues) as StacFlagKeys
