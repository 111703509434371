import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { networkSets } from 'src/constants/networkSets'
import { networkLogos } from 'src/constants/networkLogos'
import {
  defaultCTAURI,
  USDefaultCtaTextShort,
  LatinoDefaultCtaText_ES,
  LatinoDefaultCtaTextShort,
  USDefaultCtaText_ES,
  CanadaDefaultCtaTextShort,
} from 'src/modules/utils/constants'
import { selectCTAParams } from 'src/modules/reducers/params'
import { useSelector } from 'react-redux'
import { createUrl } from 'src/modules/utils/createUrl'
import { Link } from 'react-scroll'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import CTAButton from 'src/components/common/CTAButtonNew'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import Image from '../Image'
import { getCtaText } from 'src/modules/utils/language/buttons'

// import gsap from 'gsap'
// import { useGSAP } from '@gsap/react'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'

const handleNetworks = (networkSet, customNetworks) => {
  let selectedNetworks = []
  if (networkSet) {
    // find the passed network set and make a copy
    selectedNetworks = [...networkSets[networkSet]]
  } else {
    // or else, use the default set
    selectedNetworks = [...networkSets.default]
  }

  if (customNetworks?.length) {
    const addedNetworks = customNetworks
    const additionalNetworks = []
    addedNetworks.forEach(addition => {
      const networkComponent = networkLogos[addition]
      additionalNetworks.push(networkComponent)
      // check if the custom network already exists in the chosen network set, if so, remove it
      const index = selectedNetworks.findIndex(network => network === networkComponent)
      if (index > -1) {
        selectedNetworks.splice(index, 1)
      }
    })
    // finally, add the custom networks to the start of the set
    selectedNetworks.unshift(...additionalNetworks)
  }
  return selectedNetworks
}

const NetworkSection = ({
  ctaHrefOverride,
  ctaText,
  customNetworks,
  networkSet,
  headline,
  subtitle,
  maxCount,
  lang = 'en',
  market = 'us',
  noBorder,
  onClick,
  hasOfferInput,
  disableFreeTrial,
}) => {
  // get ctaParams from redux
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  let defaultNetworks = []

  switch (market) {
    case 'us':
      defaultNetworks = handleNetworks('default', customNetworks)
      break
    case 'canada':
      defaultNetworks = handleNetworks('canadaEssentials', customNetworks)
      break
    case 'latino':
      defaultNetworks = handleNetworks('entertainmentLatino', customNetworks)
      break
    default:
      defaultNetworks = []
  }

  const [networks, setNetworks] = React.useState(defaultNetworks)

  React.useEffect(() => {
    setNetworks(handleNetworks(networkSet, customNetworks))
  }, [networkSet, customNetworks])

  const defaultCtaText =
    ctaText || getCtaText({ lang, market, disableFreeTrial, isTextShort: true })

  const copyUS = {
    en: {
      headline: 'Stream Live TV from 200+ Channels You Love',
      subtitle: 'Start for free. Cancel online anytime.',
    },
    es: {
      headline: 'Más de 200 canales en vivo de la TV que amas',
      subtitle: 'Prueba gratis. Cancela cuando quieras.',
    },
  }

  const copyCA = {
    headline: 'Stream the sports and entertainment you love.',
    subtitle: 'Watch the Premier League, Serie A, Coppa Italia & so much more.',
  }

  const copyLatino = {
    en: {
      headline: 'The Best of Sports from the Channels You Love',
      subtitle: 'Cancel online anytime.',
    },
    es: {
      headline: 'Disfruta más de 60 canales de TV que amas',
      subtitle: 'Cancela cuando quieras',
    },
  }

  let copy = copyUS[lang]

  switch (market) {
    case 'canada':
      copy = copyCA
      break
    case 'latino':
      copy = copyLatino[lang]
      break
    default:
      copy = copyUS[lang]
  }

  const handleOnClick = e => {
    fireUIInteractionEvent(
      {
        element: copy.ctaText,
        component: 'network_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  const handleMoreClick = e => {
    fireUIInteractionEvent(
      {
        element: 'More',
        component: 'network_section_more_cta_clicked',
      },
      stateForProtobuf
    )
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'network')

  // // GSAP animation
  // gsap.registerPlugin(useGSAP, ScrollTrigger)

  // const gsapContainer = useRef()

  // useGSAP(
  //   () => {
  //     const networksItems = gsap.utils.toArray('.networks-item')
  //     console.log('networks', networksItems)
  //     gsap.from(networksItems, {
  //       opacity: 0,
  //       y: 100,
  //       duration: 0.8,
  //       stagger: {
  //         each: 0.05,
  //         from: 'start',
  //       },
  //       scrollTrigger: {
  //         trigger: '.networks-row',
  //         start: 'top bottom',
  //         once: true,
  //       },
  //     })
  //     return () => {
  //       // custom cleanup code here (runs when it STOPS matching)
  //     }
  //   },
  //   { dependency: [networks], scope: gsapContainer, revertOnUpdate: true }
  // )

  return (
    // <StyledNetworkSection noBorder={noBorder} ref={gsapContainer}>
    <StyledNetworkSection noBorder={noBorder}>
      <div className="networks-wrapper">
        <div className="network-header">
          <h2>{headline || copy.headline}</h2>
          {/* <h3>{subtitle || copy.subtitle}</h3> */}
        </div>
        <div className="networks-content">
          {/* <div className="networks-row networks-top">
            {networks.length &&
              networks.slice(0, 4).map((network, i) => {
                const imgSrc = network.logoUrl + '?w=200&ch=width&auto=format,compress'
                return (
                  <li key={i} className="networks-item">
                    <img src={imgSrc} alt={network.alt} />
                  </li>
                )
              })}
          </div> */}

          <div className="networks-row">
            {networks.length &&
              networks.slice(0, maxCount || 7).map((network, i) => {
                const imgSrc = network.logoUrl + '?w=220'
                return (
                  <li key={i} className="networks-item">
                    <Image src={imgSrc} alt={network.alt} />
                  </li>
                )
              })}
            <li className="networks-item">
              <Link
                to="channels-section"
                smooth={true}
                duration={500}
                onClick={handleMoreClick}
                className="more-text"
              >
                {lang === 'es' ? 'Y MÁS' : '+ MORE'}
              </Link>
            </li>
          </div>
        </div>
        <div className="cta-container">
          <CTAButton
            link={hasOfferInput ? '#' : ctaHrefOverride || ctaURL}
            onClick={onClick || handleOnClick}
          >
            {defaultCtaText}
          </CTAButton>
        </div>
      </div>
    </StyledNetworkSection>
  )
}

export default NetworkSection

NetworkSection.propTypes = {
  lang: PropTypes.oneOf(['en', 'es']),
  plan: PropTypes.oneOf(['us', 'canada', 'latino']),
}

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

// 3:2 Figma to Mobile (calculate 2/3 of figma sizing for mobile)

const StyledNetworkSection = styled.section`
  background: #fafafa;
  overflow: hidden;

  .networks-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .network-header {
    margin: 40px 10px;
    text-align: center;

    @media (min-width: ${desktopBreak}) {
      margin: 86px 20px 65px;
    }

    h2 {
      color: #040911;
      text-align: center;
      max-width: 300px;
      font-size: 25px;
      font-weight: 800;
      line-height: 1.1;

      @media (min-width: ${desktopBreak}) {
        font-size: 36px;
        max-width: 100%;
      }
    }

    ${'' /* h3 {
      color: rgba(255, 255, 255, 0.7);
      font-size: 22.4px;
      line-height: 1.5em;
      font-weight: 400;
    } */}
  }

  .networks-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 26px;
    width: 100vw;
    position: relative;
    margin-bottom: 44px;

    @media (min-width: ${desktopBreak}) {
      ${'' /* left: 75vw;
      gap: 30px;
      flex-wrap: nowrap;
      margin-bottom: 52px; */}
    }
  }
  .networks-row {
    max-width: 400px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 24px 24px;
    padding: 0 40px;
    margin: 0 auto;
    position: relative;

    @media (min-width: ${desktopBreak}) {
      max-width: 900px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 30px 30px;
      padding: 0 60px;
    }
  }

  ${'' /* .networks-top {
    @media (min-width: ${desktopBreak}) {
      left: 0;
    }
  }

  .networks-bottom {
    @media (min-width: ${desktopBreak}) {
      left: 0;
    }
  } */}

  .networks-item {
    ${'' /* width: 122px;
    height: 71px; */}
    width: 100%;
    aspect-ratio: 122/71;
    background: #fff;
    ${'' /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4); */}
    border: 1px solid #DADADA;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    list-style: none;
    margin: 0;

    @media (min-width: ${desktopBreak}) {
      ${'' /* width: 183px;
      height: 107px; */}
      aspect-ratio: 183/107;
      border-radius: 9px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      max-width: 88px;
      max-height: 45px;

      @media (min-width: ${desktopBreak}) {
        ${'' /* max-width: 133px;
        max-height: 68px; */}
        max-width: 126px;
        max-height: 56px;
      }
    }

    .more-text {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: #000000;
      white-space: nowrap;
      cursor: pointer;
      @media (min-width: ${desktopBreak}) {
        font-size: 24px;
      }
    }
  }

  .cta-container {
    margin: 0px auto 30px;
    text-align: center;
  }

  .desktop-line-break {
    display: block;
    @media (min-width: ${desktopBreak}) {
      display: inline;
    }
  }
`
