export const promoCodes = {
  canada_cricket_qt: 'ca-recu-mk-sports-qt-nt-54amtoff2-v1',
  canada_cricket_yr: 'ca-recu-mk-sports-yr-nt-198amtoff1-v1',
  canada_entertainment_plus: 'canada-month-entertainment-plus',
  canada_entertainment_plus_quarterly: 'canada-quarter-entertainment-plus',
  canada_lenovo: 'canada-month-essentials-lenovo-promo',
  canada_mastercard: 'canada-month-essentials-mastercard-30-off',
  canada_mlbtv_mo: 'canada-month-essentialsmlbtv-v3-promo',
  canada_mlbtv_qt: 'canada-quarter-essentials-v5-mlbtv-promo',
  canada_mlbtv_yr: 'canada-year-essentials-v5-promo-mlbtv',
  canada_sabres_mo: 'ca-recu-mk-sports_sabre-mo-14d-17amtoff12-v1',
  canada_sabres_qt: 'ca-recu-mk-sports_sabre-qt-14d-45amtoff4-v1',
  canada_sabres_yr: 'ca-recu-mk-sports_sabre-yr-14d-150amtoff1-v1',
  canada_soccer: 'canada-month-soccer-affiliate',
  canada_soccer_mo: 'ca-recu-mk-sports-mo-nt-5amtoff1-v1',
  canada_soccer_qt: 'ca-recu-mk-sports-qt-nt-15amtoff1-v1',
  canada_soccer_yr: 'ca-recu-mk-sports-yr-nt-20amtoff1-v1',
  canada_SPC: 'canada-month-essentials-spc',
  charter_elite_plan: 'us-recu-mk-elite_comcast-mo-5d-28amtoff2-v1',
  charter_pro_plan: 'us-recu-mk-pro_comcast-mo-5d-25amtoff2-v1',
  colin_cowherd: 'us-recu-bd-pro_ccowherd-mo-5d-25amtoff2-v1',
  crunch: 'us-recu-bd-pro-mo-5d-15pctoff1-v1',
  denver_nuggets: 'pro-denver-nuggets-14d-trial',
  detroit_pistons: 'us-recu-ga-fanduel_det-mo-14d-sponsorship',
  epic_golf: 'us-recu-mk-pro_epic-mo-30d-v2',
  epic_golf_promo: 'us-recu-bd-pro-mo-5d-15pctoff1-v1',
  ftf_canada: 'canada-quarter-essentials-ftf-30-off',
  fubotv_amex: 'elite-amex-promo-3mon',
  govX: 'us-recu-bd-pro_govx-mo-5d-10amtoff2-v1',
  lenovo: 'us-recu-bd-elite_lenovo-mo-5d-30pctoff3-v1',
  links_golf: 'us-recu-bd-pro-mo-5d-15pctoff1-v1',
  links_golf_club: 'us-recu-mk-pro_links-mo-30d-v2',
  offer: 'us-recu-mk-elite_comcast-mo-5d-28amtoff2-v1',
  optimum_pro_plan: 'us-recu-mk-pro_optimum-mo-5d-25amtoff2-v1',
  troon_14: 'us-recu-mk-pro_troon-mo-14d-v2',
  troon_30: 'us-recu-mk-pro_troon-mo-30d-v2',
}
