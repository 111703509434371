export const imgxUrl = 'https://assets-imgx.fubo.tv/marketing/lp/images/'
export const videoUrl = 'https://assets.fubo.tv/marketing/lp/videos/'
export const videoUrlNew = 'https://assets-videos.fubo.tv/marketing/lp/'

export { ratePlanCodes } from './ratePlanCode'
export { promoCodes } from './promoCodes'
export { videos } from './videos'
export { images } from './images'
export { networkLogos } from './networkLogos'
export { leagueLogos } from './leagueLogos'
export { championshipLogos } from './championshipLogos'
export { networkSets } from './networkSets'
export { caPlanPricing } from './caPlanData'
export { usPlanPricing } from './usPlanData'
export { caPlanCardOffers } from './caPlanCardOffers'
