import React, { useEffect } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
// import { useDispatch } from 'react-redux'
// import { updateParams } from 'src/modules/reducers/params'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { networkLogos } from 'src/constants/networkLogos'
import { promoCodes } from 'src/constants/promoCodes'

export default props => {
  // const dispatch = useDispatch()

  // const params = {
  //   irad: '366904',
  //   irmp: '365718',
  // }

  // useEffect(() => {
  //   dispatch(updateParams(params))
  // }, [])

  return (
    <StyledContainer>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        offerCode={promoCodes.optimum_pro_plan}
        offerBanner={
          <>
            {/* <div className="spectrum-offer-banner">
             <div className="offer-banner-image">
              <SpectrumLogo />
            </div> */}
            {/* <div className="offer-banner-text"> */}
            ✔&#xfe0e; Optimum discount applied!
            <br />
            Optimum customers get 30% off your first two months
            {/* </div>
          </div> */}
          </>
        }
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Watch MSG Networks live"
        subtitle="SEE THE KNICKS, RANGERS, ISLANDERS, DEVILS, SABRES & MORE"
        tagline="Stream live games and shows from any device. Regional restrictions apply."
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-optimum.png?ch=width&auto=format,compress"
        // logoHeight="80px"
        // ctaText="Start Watching"
        networkSubtitle={'Cancel online anytime.'}
        hasOptimumPlanCards={true}
        removeCable={true}
        networkSet="baseball"
        customNetworks={['msg', 'yesnetwork', 'fox', 'espn', 'fs1', 'nflnetwork', 'cbs', 'espn2']}
        showRSN={true}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .offer-banner {
    /* background-color: #003057; */
    background: linear-gradient(94.26deg, #02579f 35.69%, #0173d0 98.83%);
    padding-left: 8px;
    .banner-title {
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    .spectrum-offer-banner {
      display: flex;
      align-items: center;
      justify-content: center;
      .offer-banner-image {
        padding-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 160px;
          @media (max-width: 767px) {
            width: 90px;
          }
        }
      }
      .offer-banner-text {
        padding-left: 16px;
        text-align: left;
        border-left: 1px solid #fff;
      }
    }
  }
`

// import React from 'react'
// import RedirectTemplate from 'src/components/template/redirect'
// export default props => <RedirectTemplate location={props.location} destination="/tv/" />
