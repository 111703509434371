import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { leagueGroupIds, sportIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'
export default props => (
  <StyledContainer>
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      addonCode="premier-intl-sports-plus"
      video={videos.cricket.videoUrl}
      title="Watch live cricket matches"
      subtitle="CATCH THE BIGGEST EVENTS OF THE YEAR"
      tagline="Stream them live from your TV & other devices"
      customNetworks={['willow']}
      sportId={sportIds.Cricket}
      matchtickerSportId={sportIds.Cricket}
      matchtickerLeagueName="Cricket"
      matchtickerLeagueId={leagueGroupIds.cricket}
      caRedirectUrl={'/ca/cricket/'}
      additionalFAQs={additionalFAQs}
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 600px;
  }
`

const additionalFAQs = [
  {
    question: 'Can I watch live cricket matches on Fubo?',
    answer:
      'Yes, you can watch live cricket matches on Fubo through Willow, which provides extensive coverage of cricket events.',
  },
  {
    question: 'How can I find specific cricket matches on Fubo?',
    answer:
      'To find specific cricket matches on Fubo, simply use the search feature and type in the name of the match or team. You can also browse through the Willow channel for a schedule of upcoming games.',
  },
  {
    question: 'Does Fubo offer cricket commentary and analysis shows?',
    answer:
      'Yes, Fubo offers cricket commentary and analysis shows through Willow, providing in-depth insights and expert opinions on ongoing matches.',
  },
  {
    question: 'Can I watch recorded cricket matches on Fubo?',
    answer:
      "Yes, you can watch recorded cricket matches on Fubo if they are available on Willow's replay feature. Check the channel for any past matches you might have missed.",
  },
  {
    question: 'What cricket events are covered live on Fubo?',
    answer:
      'Fubo covers a variety of live cricket events, including international matches, domestic tournaments, and special series, all available through the Willow channel.',
  },
  {
    question: 'How do I access cricket highlights on Fubo?',
    answer:
      'You can access cricket highlights on Fubo by navigating to the Willow channel. Highlights of recent matches are often featured and can be watched on-demand.',
  },
  {
    question: 'Are there any cricket shows available on Fubo?',
    answer:
      'Yes, Fubo features various cricket shows that cover analysis, player interviews, and match previews, all available through the Willow channel.',
  },
  {
    question: 'Can I watch cricket tournaments on Fubo?',
    answer:
      'Absolutely! You can watch major cricket tournaments on Fubo, including the ICC events and various T20 leagues, all broadcasted live on Willow.',
  },
]
