import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
import { ratePlanCodes } from 'src/constants'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="es"
    market="latino"
    callsign="BEIN1HD"
    offerCode={ratePlanCodes.latino}
    title="Mira beIN Sports en vivo con Fubo"
    networkSet="entertainmentLatino"
    networkName="beIN Sports"
  />
)
