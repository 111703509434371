import React, { useEffect, useRef, useState } from 'react'
import CTAButton from 'src/components/common/CTAButton'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { useEPLImage } from 'src/modules/hooks/useEPLImage'
import styled from 'styled-components'
import binRanges from 'src/constants/mastercardBins.json'
import { parseQueryParams } from 'src/modules/utils/parseQueryParams'
import { useDispatch } from 'react-redux'
import { updateParams } from 'src/modules/reducers/params'
import { caPlanPricing } from 'src/constants/caPlanData'
import { promoCodes } from 'src/constants'
const FootnoteSection = () => {
  return (
    <StyledFootnoteSection id="tc">
      <p style={{ textDecoration: 'underline' }}>Fubo TV Terms &amp; Conditions</p>
      <p>
        Eligible Mastercard* cardholders who are new subscribers** of Fubo will receive a C$30
        discount on Fubo Canada Sports quarterly plan when they pay with their Eligible Mastercard.
        The offer is valid one time only. Each eligible World and World Elite cardholder will
        receive the C$30 discount on his or her first payment only (total first quarterly payment
        with discount applied will be equal to C$49.97*** + applicable taxes) and thereafter, the
        quarterly subscription will automatically renew at a cost of C$
        {caPlanPricing.soccer.totalPrice.quarterly}*** per quarter + applicable taxes until
        cancellation. To access the offer and subscribe to a Fubo Sports Quarterly plan, you must
        register at{' '}
        <a href="https://www.fubo.tv/stream/ca/mastercard/">https://fubo.tv/stream/ca/mastercard</a>
        . The $30 discount on the Sports Quarterly plan will be deducted from the cost of the plan
        at the time of checkout. Additional taxes may be applied.
      </p>
      <p>
        Additional terms and conditions apply. Your subscription is subject to Fubo’s Terms of
        Service and Privacy Policy available at: Fubo Terms of Service. Terms, conditions,
        subscription pricing, features, programming, and service and support options subject to
        change without notice. You can cancel your subscription through your Account Page at any
        time before renewal, visit Fubo TV website (
        <a href="https://www.fubo.tv/account">My Account</a>). In the event of cancellation, no
        credits or refunds will be provided for subscription fees already paid. Mastercard and Fubo
        reserve the rights to modify or end this offer at any time and for any reason, without
        notice.
      </p>
      <p>
        Neither Mastercard or any participating financial institution card issuer has any
        involvement in or responsibility for the provision of any Fubo subscriptions, products, or
        services, and are not responsible for any arising claims or damages.
      </p>
      <p>Offer not available in Quebec.</p>
      <p>
        *The following cards issued in Canada: World Mastercard, World Elite Mastercard, and Muse by
        Mastercard that are eligible. Check here for eligibility:{' '}
        <a href="https://www.fubo.tv/stream/ca/mastercard/">https://fubo.tv/stream/ca/mastercard</a>
        .
      </p>
      <p>
        **A “new subscriber” eligible for the one-time C$30 discount is someone who has not
        registered for Fubo services previously as determined solely by Fubo. Subscribers who have a
        subscription to Fubo services would not be eligible to redeem this offer.
      </p>
      <p>
        *** Price subject to change. Visit checkout page or{' '}
        <a href="https://www.fubo.tv/account">My Account</a> page for current subscription price.
      </p>
    </StyledFootnoteSection>
  )
}

const StyledFootnoteSection = styled.section`
  width: 100%;
  box-sizing: border-box;
  padding: 25px 50px;
  max-width: 1200px;
  background-color: rgb(255, 255, 255);
  display: block;
  margin: 0px auto 40px;
  a {
    color: #007bff;
    text-decoration: underline;
  }
`

export default props => {
  const dispatch = useDispatch()

  const posterImg = useEPLImage()
  const [showVerify, setShowVerify] = useState(true)
  const [cardNumber, setCardNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    if (props && props.location && props.location.href) {
      const bypass = parseQueryParams(props.location.href).bypass
      if (bypass) {
        setShowVerify(false)
      }
    }
  }, [])

  const errorText =
    'Your Mastercard is not eligible for the offer. Please contact your financial institution for more information.'

  const validateCard = () => {
    const cardNumberInt = parseInt(cardNumber, 10)
    // Check if the card number falls within any of the BIN ranges
    const isValid = binRanges.some(range => {
      return cardNumberInt >= range.min && cardNumberInt <= range.max
    })

    if (isValid) {
      setShowVerify(false) // Show special offer
      setErrorMessage('') // Clear any previous error message
      // Add sharedId, irmp and irad params
      // sharedId will be the bin number
      const params = {
        irad: '2167458',
        irmp: '5771281',
        sharedId: cardNumberInt,
      }
      dispatch(updateParams(params))
    } else {
      setErrorMessage(errorText)
    }
  }

  const handleInputChange = e => {
    let value = e.target.value.replace(/\D/g, '') // Remove non-numeric characters
    setCardNumber(value)
  }

  return (
    <StyledGatedContainer className={showVerify ? 'fixed' : null}>
      {showVerify && (
        <StyledGatedOverlay show={showVerify}>
          <Popup>
            <div className="popup-content">
              <p>
                Enter the first ten digits of your eligible Mastercard to activate this exclusive
                offer.
              </p>
              <input
                ref={inputRef}
                type="text"
                value={cardNumber}
                onChange={handleInputChange}
                placeholder="Enter the first ten digits"
                maxLength="10"
              />
              <CTAButton onClick={validateCard}>Activate Offer</CTAButton>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <span className="disclaimer">
                *Offer only available to eligible World, World Elite or Muse Mastercard cardholders.
                Entering your credit card number here is for verification purposes only. For terms
                and conditions, click{' '}
                <a
                  href="/terms-conditions/ca/mastercard/"
                  style={{ textDecoration: 'underline', color: '#007bff' }}
                  target="_blank"
                >
                  here
                </a>
              </span>
            </div>
          </Popup>
        </StyledGatedOverlay>
      )}
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        offerBanner={
          <>
            ✔ Special offer for World and World Elite cardholders
            <br />
            Get $30 off 3 months of live Premier League soccer & more
          </>
        }
        offerCode={!showVerify ? promoCodes.canada_mastercard : null}
        poster={posterImg}
        noVideo={true}
        title="Watch the Premier League & more"
        subtitle="Including games from top leagues like Series A, MLB & others"
        plan="sportsOnly"
        forcePlanLength="Quarterly"
        forceAnchorLink={false}
        networkSet="canadaEssentials"
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-mastercard.png?ch=width&auto=format,compress"
        logoHeight="90px"
        planCardOverride={!showVerify ? 'mastercard' : null}
        heroCtaNote={
          <>
            Offer applies to first-time subscribers who sign up for a subscription using a
            Mastercard card associated with this offer. Offer valid one time only. Receive a $30
            discount on Fubo Canada Sports Quarterly Plan only. For terms and conditions, click{' '}
            <a href="#tc" style={{ textDecoration: 'underline' }}>
              here
            </a>
          </>
        }
        FootnoteSection={FootnoteSection}
      />
    </StyledGatedContainer>
  )
}

const StyledGatedContainer = styled.div`
  &.fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`

const StyledGatedOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  padding: 20px;
  box-sizing: border-box;
`

const Popup = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 40px 30px 30px;
  border-radius: 30px;
  max-width: 1000px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 35px 25px;
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
    color: #000;
    p {
      font-family: Qanelas;
      margin: 0;
      font-size: 20px;
      line-height: 1.2;
      font-weight: 600;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    input {
      margin-bottom: 20px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      max-width: 400px;
      font-size: 16px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      box-sizing: border-box;
      &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }
    }

    .CTAButton {
      font-size: 18px;
      padding: 12px 36px;
    }

    .disclaimer {
      font-size: 14px;
      margin-top: 20px;
    }
  }
`

const ErrorMessage = styled.span`
  color: red;
  margin-top: 10px;
`
