import React from 'react'
import { useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { selectCtaCopyContinueFlag } from 'src/modules/reducers/cohorts'

export default props => {
  const isCtaCopyContinueEnabled = useSelector(selectCtaCopyContinueFlag)
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/espn2020.mp4"
      staticAnnotations={networkLogos.espn}
      title="Watch ESPN live with Fubo"
      subtitle="SPORTING EVENTS, NEWS, ANALYSIS AND MORE"
      tagline="Stream it all from your TV, phone and other devices"
      ctaText={isCtaCopyContinueEnabled ? 'Continue' : 'Start Watching'}
      networkSet="sports"
    />
  )
}
