import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import ChannelSection from 'src/components/common/ChannelSectionNew'
import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'
import BundlePlansCAByDuration from 'src/components/common/BundlePlansCAByDuration'

import MatchTicker from 'src/components/common/MatchTicker'
import { CanadaDefaultCtaText, CanadaDefaultCtaTextShort } from 'src/modules/utils/constants'
import { CALowestPrice } from 'src/constants/caPlanData'
import BundlePlansCAByPlan from 'src/components/common/BundlePlansCAByPlan'
const CanadaTemplateDefault = ({
  lang = 'en',
  market = 'canada',
  // Offer Code
  offerCode,
  offerBanner,
  // Addon Code
  addonCode,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  tagline,
  ctaText,
  video,
  videoMobile,
  staticAnnotation,
  annotations,
  poster,
  heroCtaNote,
  // Plan Cards
  hasPlanCards = true,
  plan,
  plansTitle,
  plansSubtitle,
  soccerPlanCardImage,
  premiumPlanCardImage,
  preselectPlanLength,
  forcePlanLength,
  // Network Section
  networkHeadline,
  networkSubtitle,
  networkSet,
  customNetworks,
  // FAQ Section
  additionalFAQs,
  // Others
  team,
  // Show offer input for unique codes
  hasOfferInput,
  // Place anchor links on all CTAs to plan cards
  forceAnchorLink = true,
  // AB Test - Video vs Logo Soup
  noVideo = false,
  // AB Test for MatchTicker
  matchtickerSportId,
  matchtickerLeagueName,
  matchtickerLeagueId,
  // Plan Card Override
  planCardOverride,
  FootnoteSection,
  // Personalization Params
  sportId,
  leagueId,
  teamId,
  // Select a specific plan product on the plan cards
  forcePlanProduct,
  selectedPlanLengths,
  usRedirectUrl,
}) => {
  let metaDescription =
    pageDescription ||
    'See every match all season long. Stream live TV without cable. DVR included. No contract, cancel anytime.'

  const DefaultHeroCtaText = CanadaDefaultCtaText
  const DefaultCtaTextShort = CanadaDefaultCtaTextShort

  const [heroCtaText, setHeroCtaText] = useState(DefaultHeroCtaText)
  const [activeCtaText, setActiveCtaText] = useState(DefaultCtaTextShort)

  useEffect(() => {
    let heroCta = DefaultHeroCtaText
    let cta = DefaultCtaTextShort
    if (ctaText) {
      heroCta = ctaText
      cta = ctaText
    }

    setHeroCtaText(heroCta)
    setActiveCtaText(cta)
  }, [plan, ctaText])

  const displayDefaultSportsPromoMessaging =
    plan === 'soccer' && !forcePlanLength && !planCardOverride && !hasOfferInput

  const isAnchor = forceAnchorLink && hasPlanCards ? '#select-plan' : null

  if (displayDefaultSportsPromoMessaging) {
    offerBanner = (
      <>
        ✔ Special offer available. <br />
        Enjoy live sports for as low as ${CALowestPrice}/mo.
      </>
    )
  }

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      addonCode={addonCode}
      offerBanner={offerBanner}
      ctaHrefOverride={isAnchor}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
      usRedirectUrl={usRedirectUrl}
    >
      <LogoSyncContextProvider annotations={annotations}>
        <PageWrapper>
          <SEO
            lang={lang}
            market={market}
            title={pageTitle || title}
            description={metaDescription}
          />
          {matchtickerLeagueId && (
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
            />
          )}
          <HeroSectionNew
            logo={logo}
            logoHeight={logoHeight}
            lang={lang}
            market={market}
            heroBgVideo={video}
            heroBgVideoMobile={videoMobile}
            title={title}
            subtitle={subtitle}
            tagline={tagline}
            ctaText={heroCtaText}
            staticAnnotation={staticAnnotation}
            posterImg={poster}
            ctaHrefOverride={isAnchor}
            noVideo={noVideo}
            hasOfferInput={hasOfferInput}
            noteText={heroCtaNote}
            offerCode={offerCode}
            addonCode={addonCode}
          />
          {hasPlanCards &&
            (forcePlanProduct ? (
              <BundlePlansCAByPlan
                plan={plan}
                title={plansTitle}
                subtitle={plansSubtitle}
                selectedPlanLengths={selectedPlanLengths}
                offerCode={offerCode}
                soccerPlanCardImage={soccerPlanCardImage}
                premiumPlanCardImage={premiumPlanCardImage}
                ctaText={ctaText}
                planCardOverride={planCardOverride}
                forcePlanProduct={forcePlanProduct}
              />
            ) : (
              <BundlePlansCAByDuration
                plan={plan}
                title={plansTitle}
                subtitle={plansSubtitle}
                offerCode={offerCode}
                soccerPlanCardImage={soccerPlanCardImage}
                premiumPlanCardImage={premiumPlanCardImage}
                ctaText={ctaText}
                preselectPlanLength={preselectPlanLength}
                forcePlanLength={forcePlanLength}
                planCardOverride={planCardOverride}
              />
            ))}

          <NetworkSection
            lang={lang}
            market={market}
            headline={networkHeadline}
            subtitle={networkSubtitle}
            networkSet={networkSet}
            customNetworks={customNetworks}
            ctaText={activeCtaText}
            ctaHrefOverride={isAnchor}
            hasOfferInput={hasOfferInput}
          />
          <ChannelSection
            lang={lang}
            market={market}
            showAllChannels={true}
            title="Your Fubo channel lineup"
          />
          <DealSection
            lang={lang}
            market={market}
            savingsText={
              plan === 'entertainment' && (
                <>
                  Fubo is the only way to watch the Premier League, now with matches in 4K UHD.
                  Plus, get Serie A & Coppa Italia too.
                  <span style={{ fontSize: '.7em', fontStyle: 'italic', display: 'block' }}>
                    *Sports and Premium plans only.
                  </span>
                </>
              )
            }
          />
          <PlatformSection
            lang={lang}
            market={market}
            ctaText={activeCtaText}
            ctaHrefOverride={isAnchor}
            hasOfferInput={hasOfferInput}
          />
          <FAQSection
            lang={lang}
            market={market}
            team={team}
            canadaEnt={plan === 'entertainment'}
            additionalFAQs={additionalFAQs}
          />
          {FootnoteSection && <FootnoteSection />}
        </PageWrapper>
      </LogoSyncContextProvider>
    </Layout>
  )
}

export default CanadaTemplateDefault

const PageWrapper = styled.div`
  .hero-content-text {
    h1 span {
      display: inline-block;
    }
  }
  .hero-placeholder {
    min-height: 700px;
    color: #fff;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
