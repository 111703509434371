import React from 'react'
import { caPlanCardOffers } from 'src/constants/caPlanCardOffers'
import { ratePlanCodes } from 'src/constants'
import { CanadaDefaultCtaText } from 'src/modules/utils/constants'

export const CALowestPrice = 18.33

export const isControlVariant = flag => {
  return flag === 'control' || flag === 'default' || !flag
}

export const caPlanPricing = {
  entertainment: {
    totalPrice: {
      monthly: 16.99,
      quarterly: 47.97,
    },
    discountedTotalPrice: {
      monthly: null,
      quarterly: null,
    },
  },
  soccer: {
    totalPrice: {
      monthly: 29.99,
      quarterly: 79.97,
      annual: 279.99,
    },
    discountedTotalPrice: {
      monthly: null,
      quarterly: 64.99,
      annual: 219.99,
    },
  },
  premium: {
    totalPrice: {
      monthly: 42.99,
      quarterly: 104.98,
      annual: 395.88,
    },
    discountedTotalPrice: {
      monthly: null,
      quarterly: null,
      annual: null,
    },
  },
}

export const caPlanData = (channels, offerKey, isIncludeCRTCEnabled) => {
  const entertainmentChannels = channels?.['entertainment'] || 64
  const soccerChannels = channels?.['soccer'] || 79
  const premiumChannels = channels?.['premium'] || 101

  let data = {
    entertainment: {
      promoImg: 'https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_ent.jpg?w=451',
      plan: 'entertainment',
      title: 'Entertainment',
      subtitle: 'Best for Families',
      listItems: [
        { available: true, text: `${entertainmentChannels} channels - 1000's of popular titles` },
        { available: false, text: 'Premier League matches' },
        {
          available: true,
          text: 'Lifestyle channels - Global TV, Slice & more',
        },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Cloud DVR - Up to 1000 hours of space' },
      ],
      footnote: '',

      offerCode: {
        monthly: ratePlanCodes.canada_ent_mo,
        quarterly: ratePlanCodes.canada_ent_qt,
      },
      totalPrice: caPlanPricing.entertainment.totalPrice,

      ctaText: CanadaDefaultCtaText,
    },
    soccer: {
      promoImg: 'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_soccer-2.jpg?w=451',
      plan: 'soccer',
      title: 'Sports',
      subtitle: 'Best for Soccer and Sports Fans',
      listItems: [
        { available: true, text: `${soccerChannels} channels - Soccer and sports` },
        { available: true, text: 'All Premier League matches' },
        { id: '4k', available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Serie A, Coppa Italia, Ligue 1 & more' },
        { available: true, text: 'Cloud DVR - 250 hours of space' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),

      offerCode: {
        monthly: ratePlanCodes.canada_sports_mo,
        quarterly: ratePlanCodes.canada_sports_qt,
        annual: ratePlanCodes.canada_sports_yr,
      },
      savingsNote: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      totalPrice: caPlanPricing.soccer.totalPrice,
      discountedTotalPrice: caPlanPricing.soccer.discountedTotalPrice,
      ctaText: CanadaDefaultCtaText,
    },
    premium: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca-premium-card-img-24.jpg?w=451',
      plan: 'premium',
      title: 'Premium',
      subtitle: 'Best for Everyone',
      listItems: [
        { available: true, text: `${premiumChannels} channels - Entertainment and sports` },
        { available: true, text: 'All Premier League & Serie A matches' },
        { available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Includes NBA TV' },
        {
          available: true,
          text: 'Lifestyle channels - Global TV, Slice & more',
        },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Cloud DVR - 1000 hours of space' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),

      offerCode: {
        monthly: ratePlanCodes.canada_premium_mo,
        quarterly: ratePlanCodes.canada_premium_qt,
        annual: ratePlanCodes.canada_premium_yr,
      },
      // Promo pricing
      savingsNote: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      totalPrice: caPlanPricing.premium.totalPrice,
      discountedTotalPrice: caPlanPricing.premium.discountedTotalPrice,
      ctaText: CanadaDefaultCtaText,
      // ctaText: 'Start For Free',
    },
  }

  // adjust card data for promo offers
  if (offerKey) data = caPlanCardOffers(data, offerKey)

  // calculate per month numbers
  data = calculatePrices(data, isIncludeCRTCEnabled)
  data = addFootnotes(data, isIncludeCRTCEnabled)

  return data
}

const shouldIncludeFee = (flag, duration = '', planType = '') => {
  // Entertainment never has fees
  if (planType === 'entertainment') {
    return false
  }

  // Control/default/null never have fees
  if (isControlVariant(flag)) {
    return false
  }

  // Special case: Soccer monthly only has fee with test-with-fee-monthly flag
  if (planType === 'soccer' && duration === 'monthly') {
    return flag === 'test-with-fee-monthly'
  }

  // All other cases in test variants have fees
  return true
}

const calculatePrices = (data, isIncludeCRTCEnabled) => {
  const addFee = (price, duration = '', planType = '') => {
    if (!shouldIncludeFee(isIncludeCRTCEnabled, duration, planType)) {
      return price
    }
    return (Math.round(price * 1.05 * 100) / 100).toFixed(2)
  }

  const createFee = price => {
    return price ? price * 0.05 : null
  }

  let newData = {
    entertainment: {
      ...data.entertainment,
      price: `C$${data.entertainment.totalPrice.monthly}/mo`,
      pricePerMonth: {
        monthly: data.entertainment.totalPrice.monthly,
        quarterly: data.entertainment.totalPrice.quarterly / 3,
      },
      // broadcastFee: {
      //   monthly: createFee(data.entertainment.totalPrice.monthly),
      //   quarterly: createFee(data.entertainment.totalPrice.quarterly),
      // },
      // broadcastFeePerMonth: {
      //   monthly: createFee(data.entertainment.totalPrice.monthly),
      //   quarterly: createFee(data.entertainment.totalPrice.quarterly / 3),
      // },
    },
    soccer: {
      ...data.soccer,
      price: 'Starting at C$23.33/mo',
      pricePerMonth: {
        monthly: addFee(data.soccer.totalPrice.monthly, 'monthly', 'soccer'),
        quarterly: addFee(data.soccer.totalPrice.quarterly / 3, 'quarterly', 'soccer'),
        annual: addFee(data.soccer.totalPrice.annual / 12, 'annual', 'soccer'),
      },
      discountedPricePerMonth: {
        monthly: addFee(data.soccer.discountedTotalPrice.monthly, 'monthly', 'soccer'),
        quarterly: addFee(data.soccer.discountedTotalPrice.quarterly / 3, 'quarterly', 'soccer'),
        annual: addFee(data.soccer.discountedTotalPrice.annual / 12, 'annual', 'soccer'),
      },
      broadcastFee: {
        monthly: createFee(data.soccer.totalPrice.monthly),
        quarterly: createFee(data.soccer.totalPrice.quarterly),
        annual: createFee(data.soccer.totalPrice.annual),
      },
      discountedBroadcastFee: {
        monthly: createFee(data.soccer.discountedTotalPrice.monthly),
        quarterly: createFee(data.soccer.discountedTotalPrice.quarterly),
        annual: createFee(data.soccer.discountedTotalPrice.annual),
      },
      broadcastFeePerMonth: {
        monthly: createFee(data.soccer.totalPrice.monthly),
        quarterly: createFee(data.soccer.totalPrice.quarterly / 3),
        annual: createFee(data.soccer.totalPrice.annual / 12),
      },
      discountedBroadcastFeePerMonth: {
        monthly: createFee(data.soccer.discountedTotalPrice.monthly),
        quarterly: createFee(data.soccer.discountedTotalPrice.quarterly / 3),
        annual: createFee(data.soccer.discountedTotalPrice.annual / 12),
      },
    },
    premium: {
      ...data.premium,
      price: 'Starting at C$23.33/mo',
      pricePerMonth: {
        monthly: addFee(data.premium.totalPrice.monthly, 'monthly', 'premium'),
        quarterly: addFee(data.premium.totalPrice.quarterly / 3, 'quarterly', 'premium'),
        annual: addFee(data.premium.totalPrice.annual / 12, 'annual', 'premium'),
      },
      discountedPricePerMonth: {
        monthly: addFee(data.premium.discountedTotalPrice.monthly, 'monthly', 'premium'),
        quarterly: addFee(data.premium.discountedTotalPrice.quarterly / 3, 'quarterly', 'premium'),
        annual: addFee(data.premium.discountedTotalPrice.annual / 12, 'annual', 'premium'),
      },
      broadcastFee: {
        monthly: createFee(data.premium.totalPrice.monthly),
        quarterly: createFee(data.premium.totalPrice.quarterly),
        annual: createFee(data.premium.totalPrice.annual),
      },
      discountedBroadcastFee: {
        monthly: createFee(data.premium.discountedTotalPrice.monthly),
        quarterly: createFee(data.premium.discountedTotalPrice.quarterly),
        annual: createFee(data.premium.discountedTotalPrice.annual),
      },
      broadcastFeePerMonth: {
        monthly: createFee(data.premium.totalPrice.monthly),
        quarterly: createFee(data.premium.totalPrice.quarterly / 3),
        annual: createFee(data.premium.totalPrice.annual / 12),
      },
      discountedBroadcastFeePerMonth: {
        monthly: createFee(data.premium.discountedTotalPrice.monthly),
        quarterly: createFee(data.premium.discountedTotalPrice.quarterly / 3),
        annual: createFee(data.premium.discountedTotalPrice.annual / 12),
      },
    },
  }

  newData.entertainment.price = lowestPrice(newData.entertainment)
  newData.soccer.price = lowestPrice(newData.soccer)
  newData.premium.price = lowestPrice(newData.premium)

  return newData
}

const addFootnotes = (data, isIncludeCRTCEnabled) => {
  let newData = {
    entertainment: {
      ...data.entertainment,
      cardFootnote: {
        monthly: createFootnote(data.entertainment, 'Monthly', false),
        quarterly: createFootnote(data.entertainment, 'Quarterly', false),
      },
    },
    soccer: {
      ...data.soccer,
      cardFootnote: {
        monthly: createFootnote(
          data.soccer,
          'Monthly',
          shouldIncludeFee(isIncludeCRTCEnabled, 'monthly', 'soccer')
        ),
        quarterly: createFootnote(
          data.soccer,
          'Quarterly',
          shouldIncludeFee(isIncludeCRTCEnabled, 'quarterly', 'soccer')
        ),
        annual: createFootnote(
          data.soccer,
          'Annual',
          shouldIncludeFee(isIncludeCRTCEnabled, 'annual', 'soccer')
        ),
      },
    },
    premium: {
      ...data.premium,
      cardFootnote: {
        monthly: createFootnote(
          data.premium,
          'Monthly',
          shouldIncludeFee(isIncludeCRTCEnabled, 'monthly', 'premium')
        ),
        quarterly: createFootnote(
          data.premium,
          'Quarterly',
          shouldIncludeFee(isIncludeCRTCEnabled, 'quarterly', 'premium')
        ),
        annual: createFootnote(
          data.premium,
          'Annual',
          shouldIncludeFee(isIncludeCRTCEnabled, 'annual', 'premium')
        ),
      },
    },
  }

  return newData
}

const createFootnote = (data, duration, isIncludeCRTCEnabled) => {
  const totalPrice = data.totalPrice[duration.toLowerCase()]
  const discountedTotalPrice = data.discountedTotalPrice?.[duration.toLowerCase()]
  const totalFee = data.broadcastFee?.[duration.toLowerCase()]
    ? (Math.round(data.broadcastFee?.[duration.toLowerCase()] * 1e2) / 1e2).toFixed(2)
    : null
  const discountedTotalFee = data.discountedBroadcastFee?.[duration.toLowerCase()]
    ? (Math.round(data.discountedBroadcastFee?.[duration.toLowerCase()] * 1e2) / 1e2).toFixed(2)
    : null
  const priceWithFee = Math.round(totalPrice * 1.05 * 1e2) / 1e2
  const discountedPriceWithFee = Math.round(discountedTotalPrice * 1.05 * 1e2) / 1e2
  const name = data.title

  let numOfMonths
  switch (duration) {
    case 'Annual':
      numOfMonths = 12
      break
    case 'Quarterly':
      numOfMonths = 3
      break
    default:
      break
  }
  if (isIncludeCRTCEnabled) {
    if (discountedTotalPrice && numOfMonths) {
      return `*Average cost per month. Charged for the first ${numOfMonths} months at C$${discountedPriceWithFee}, then C$${priceWithFee} every ${numOfMonths} months. Prices subject to change.`
    } else if (numOfMonths) {
      return `*Average cost per month. Charged every ${numOfMonths} months at C$${priceWithFee}. Prices subject to change.`
    } else if (discountedTotalPrice) {
      return `*Renews at full price of C$${priceWithFee}. Prices subject to change.`
    }
  } else if (discountedTotalPrice && numOfMonths) {
    return `*Average cost per month. Charged for the first ${numOfMonths} months at C$${discountedTotalPrice}${
      discountedTotalFee ? ` (+ C$${discountedTotalFee} broadcast fee)` : ''
    } , then C$${totalPrice}${
      totalFee ? ` (+ C$${totalFee} broadcast fee)` : ''
    } every ${numOfMonths} months. Prices subject to change.`
  } else if (numOfMonths) {
    return `*Average cost per month. Charged every ${numOfMonths} months at C$${totalPrice}${
      totalFee ? ` (+ C$${totalFee} broadcast fee)` : ''
    } Prices subject to change.`
  } else if (discountedTotalPrice) {
    return `*Renews at full price of C$${totalPrice}${
      totalFee ? ` (+ C$${totalFee} broadcast fee)` : ''
    } Prices subject to change.`
  }
}

const lowestPrice = data => {
  const lowest = Math.min(
    data.pricePerMonth.monthly,
    data.pricePerMonth.quarterly,
    data.pricePerMonth.annual,
    data.discountedPricePerMonth?.monthly,
    data.discountedPricePerMonth?.quarterly,
    data.discountedPricePerMonth?.annual
  )

  const highest = Math.max(
    data.pricePerMonth.monthly,
    data.pricePerMonth.quarterly,
    data.pricePerMonth.annual,
    data.discountedPricePerMonth?.monthly,
    data.discountedPricePerMonth?.quarterly,
    data.discountedPricePerMonth?.annual
  )

  return `${lowest !== highest ? 'Starting at ' : ''}C$${lowest}/mo`
}

export const newTabData = {
  monthly: {
    tabNote: '',
  },
  quarterly: {
    tabNote: '',
    // tabNote: 'Save up to 13%',
  },
  annual: {
    tabNote: '',
    // tabNote: 'Save up to 26%',
  },
}
