import React from 'react'
import AdcashTemplate from 'src/components/template/adcash'
export default props => (
  <AdcashTemplate
    {...props}
    title={
      <>
        Premier League <span className="desktop-line-break">is better with Fubo</span>
      </>
    }
    ctaURL={'/stream/ca/epl/'}
    image={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/Fubo-4k-web-v2.png'}
    imageMobile={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/Fubo-4k-mobile-v2.png'}
  />
)
