import React, { useEffect, useState } from 'react'
import { caPlanData } from 'src/constants/caPlanData'
import { useSelector } from 'react-redux'
import PlanCardWithImage from 'src/components/common/PlanCardWithImage'
import styled from 'styled-components'
import {
  selectCanadaEntertainmentChannels,
  selectCanadaPremiumChannels,
  selectCanadaSportsChannels,
} from 'src/modules/reducers/products/selectors'
import TabSelector from 'src/components/common/TabSelector'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { selectIncludeCRTCFlag } from 'src/modules/reducers/cohorts'

const BundlePlansCAByPlan = ({
  title = 'Which plan is right for you?',
  subtitle = 'Canada’s home to streaming live soccer and top entertainment',
  plan,
  offerCode,
  soccerPlanCardImage,
  premiumPlanCardImage,
  ctaText,
  forcePlanLength,
  planCardOverride,
  forcePlanProduct,
  selectedPlanLengths,
}) => {
  const isIncludeCRTCEnabled = true

  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const canadianEntertainmentChannels = useSelector(selectCanadaEntertainmentChannels)
  const canadianSoccerChannels = useSelector(selectCanadaSportsChannels)
  const canadianPremiumChannels = useSelector(selectCanadaPremiumChannels)

  const channels = {
    entertainment: canadianEntertainmentChannels.length,
    soccer: canadianSoccerChannels.length,
    premium: canadianPremiumChannels.length,
  }

  const dict = {
    Entertainment: 'entertainment',
    Sports: 'soccer',
    Premium: 'premium',
  }

  const handleTabClick = selected => {
    setActiveTab(selected)
    fireUIInteractionEvent(
      {
        cta_name: selected,
        container_name: 'plan_length_tab_clicked',
      },
      stateForProtobuf
    )
  }

  const tabs = ['Sports', 'Premium']

  const [activeTab, setActiveTab] = React.useState(forcePlanProduct || tabs[0])
  const [planData, setPlanData] = React.useState(caPlanData())
  const [overrideOffer, setOverrideOffer] = useState(null)

  useEffect(() => {
    let data = { ...caPlanData(channels, planCardOverride, isIncludeCRTCEnabled) }

    if (soccerPlanCardImage) {
      data.soccer.promoImg = soccerPlanCardImage
    }

    if (premiumPlanCardImage) {
      data.premium.promoImg = premiumPlanCardImage
    }

    setPlanData(data)
  }, [soccerPlanCardImage, premiumPlanCardImage, planCardOverride, isIncludeCRTCEnabled])

  const planLengths = selectedPlanLengths || ['Monthly', 'Quarterly', 'Annual']

  return (
    <StyledBundleSection id="select-plan">
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      {!forcePlanProduct && (
        <div className="section-tabs">
          <TabSelector tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
        </div>
      )}
      <div className="section-content">
        {planLengths.map((length, index) => {
          const card = planData[dict[activeTab]]

          console.log('card', card)

          let listItems = card.listItems.map(item => ({ ...item }))

          const lengthLowercase = length.toLowerCase()
          {
            /* if (lengthLowercase === 'monthly' && activeTab === 'Sports') {
            listItems.find(item => item.id === '4k').available = false
            listItems.find(item => item.id === '4k').text = 'Premier League in 4K'
          } */
          }

          const discountedMonthlyFee =
            Math.round(card.discountedBroadcastFeePerMonth?.[lengthLowercase] * 1e2) / 1e2
          const monthlyFee = Math.round(card.broadcastFeePerMonth?.[lengthLowercase] * 1e2) / 1e2

          let currentPromoImg = card.promoImg

          if (planCardOverride === 'cricket') {
            card.cardFootnote.quarterly =
              '*Average cost per month. Charged for the first 6 months at C$44.97, then C$98.96 every 3 months. Prices subject to change.'
            card.cardFootnote.annual =
              '*Average cost per month. Charged for the first 12 months at C$149.99, then C$347.87 every 12 months. Prices subject to change.'
          }

          if (planCardOverride === 'cricket' && lengthLowercase === 'annual') {
            currentPromoImg =
              'https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_cricket_2.jpg?w=750&ch=width&auto=format,compress'
          }

          if (activeTab === 'Entertainment' && lengthLowercase === 'annual') {
            return null
          } else {
            return (
              <PlanCardWithImage
                key={index}
                promoImg={currentPromoImg}
                plan={card.plan}
                length={length}
                title={card.title}
                subtitle={card.subtitle}
                currency="C"
                price={Math.round(card.pricePerMonth[lengthLowercase] * 1e2) / 1e2}
                discountedPrice={
                  Math.round(card.discountedPricePerMonth?.[lengthLowercase] * 1e2) / 1e2
                }
                savingsNote={card.savingsNote?.[lengthLowercase]}
                listItems={listItems}
                offerCode={overrideOffer || card.offerCode[lengthLowercase]}
                asterisk={card.cardFootnote?.[lengthLowercase] || null}
                footnote={card.cardFootnote?.[lengthLowercase] || null}
                ctaText={ctaText || card.ctaText}
                feesNote={
                  !isIncludeCRTCEnabled && monthlyFee
                    ? `+ C$${monthlyFee.toFixed(2)}/mo broadcast fee`
                    : null
                }
                discountedFeesNote={
                  !isIncludeCRTCEnabled && discountedMonthlyFee
                    ? `+ C$${discountedMonthlyFee.toFixed(2)}/mo broadcast fee`
                    : null
                }
                isFootnoteInCard={true}
              />
            )
          }
        })}
      </div>
    </StyledBundleSection>
  )
}

export default BundlePlansCAByPlan

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 90px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-225deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%);
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 600px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin: 0 auto 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
    }
  }

  .planCard .planCard__content .planCard__header .discount__note {
    font-size: 1rem;
  }

  .planCard .planCard__content .planCard__header .faded-price,
  .planCard .planCard__content .planCard__header .asterisk {
    display: none;
  }
`
