import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { ratePlanCodes } from 'src/constants'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      offerCode={ratePlanCodes.elite}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Redzone2021_15s.mp4'}
      staticAnnotation={networkLogos.nflredzone}
      title="Watch NFL RedZone from NFL Network"
      subtitle="SEE EVERY TOUCHDOWN FROM SUNDAY AFTERNOON"
      tagline="Included with our Elite with Sports Plus plan"
      ctaText="Start Watching"
      networkSet="nfl"
      // Remove cable references
      removeCable={true}
      matchtickerSportId="111"
      matchtickerLeagueName="NFL"
      matchtickerLeagueId="191277"
      showRedzone={false}
    />
  )
}
