import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
export default props => (
  <StyledComponent>
    <USTemplateDefault
      location={props.location}
      lang="es"
      market="us"
      video={videos.seasonal_us.videoUrl}
      annotations={videos.seasonal_us.annotations}
      pageTitle="Deportes en vivo & on-demand"
      title={
        <>
          Deportes en vivo & <span className="text-nowrap">on-demand</span>
        </>
      }
      subtitle="DISFRUTA EN TU TV, COMPUTADORA O EN CUALQUIER OTRO DISPOSITIVO."
      tagline="Cuando quieras y como quieras."
      networkSet="sports"
    />
  </StyledComponent>
)

const StyledComponent = styled.div`
  .text-nowrap {
    white-space: nowrap;
  }
`
