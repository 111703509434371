import React, { useEffect, useState } from 'react'
import SEO from 'src/components/common/SEO'
import Layout from 'src/components/common/Layout'

import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'
import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectAnalyticsParameters } from 'src/modules/reducers/analytics'
import { Link } from 'gatsby'
import CharterPlanCards from 'src/components/common/CharterPlanCards'
import OptimumPlanCards from 'src/components/common/OptimumPlanCards'
import HighlightSection from 'src/components/common/HighlightSection'
import HighlightNBA from 'src/components/common/HighlightNBA'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import RSNGeolocationBannerNew from 'src/components/common/RSNOnlyBannerNew'
import NetworkSectionNew from 'src/components/common/NetworkSectionNew'
import ChannelSection from 'src/components/common/ChannelSectionNew'
import DealSectionNew from 'src/components/common/DealSectionNew'
import PlatformSectionNew from 'src/components/common/PlatformSectionNew'
import ExtraCTABanner from 'src/components/common/ExtraCTABanner'
import FAQSectionNew from 'src/components/common/FAQSectionNew'
import MatchTicker from 'src/components/common/MatchTicker'
import RedzoneBanner from 'src/components/common/RedzoneBanner'
import AddOnsSection from 'src/components/common/AddOnsSection/Index'
import { fireUIInteractionEvent, fireUIRenderEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import FanduelPlanCards from 'src/components/common/FanduelPlanCards'
import EssentialPlanCards from 'src/components/common/EssentialPlanCards'
import {
  selectMatchTickerPlacementvNoneFlag,
  selectPromoBadgeFlag,
  selectPromoBannerUSFlag,
  selectStacFlagsReady,
  selectValuePropsHeroFlag,
} from 'src/modules/reducers/cohorts'
import { isUSFreeTrial } from 'src/modules/utils/constants'
import ProPlanCards from 'src/components/common/ProPlanCards'
import styled from 'styled-components'

const PromoFootnoteStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-top: 30px;
  margin-bottom: 15px;
  color: white;
  font-size: 18px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    path {
      fill: rgb(3, 104, 255);
    }
  }
  @media (min-width: 768px) {
    font-size: 20px;
  }
`

const promoBadge = (
  <PromoFootnoteStyled>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_2)">
        <path
          d="M25 12.3105V2.34375C25 1.04932 23.9507 0 22.6562 0H12.6895C12.0679 3.25037e-06 11.4718 0.246936 11.0323 0.686475L0.686463 11.0323C-0.228821 11.9476 -0.228821 13.4315 0.686463 14.3468L10.6532 24.3135C11.5685 25.2288 13.0524 25.2288 13.9677 24.3135L24.3135 13.9677C24.7531 13.5282 25 12.9321 25 12.3105ZM19.5312 3.125C20.8257 3.125 21.875 4.17432 21.875 5.46875C21.875 6.76319 20.8257 7.8125 19.5312 7.8125C18.2368 7.8125 17.1875 6.76319 17.1875 5.46875C17.1875 4.17432 18.2368 3.125 19.5312 3.125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="25" height="25" fill="white" transform="matrix(-1 0 0 1 25 0)" />
        </clipPath>
      </defs>
    </svg>
    Special offer: $20 off one month
  </PromoFootnoteStyled>
)

const USTemplateDefault = ({
  lang = 'en',
  market = 'us',
  noRedirect,
  miceText,
  // Offer Code
  offerCode,
  offerBanner,
  // Addon Code
  addonCode,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  ctaText,
  video,
  videoMobile,
  staticAnnotation,
  annotations,
  poster,
  posterMobile,
  noteLink,
  heroCtaNote,
  // Optional Highlight Section
  highlightImage,
  highlightHeadline,
  highlightSubtitle,
  highlightNote,
  highlightBackground,
  highlightShowHeadlineTop,
  // Optional Highlight Section
  highlightNBA,
  // Network Section
  networkHeadline,
  networkSubtitle,
  networkSet,
  customNetworks,
  // Others
  team,
  // AB Test for RSN
  showRSN,
  showRedzone,
  redzoneOfferCode,
  // Show offer input for unique codes
  hasOfferInput,
  // AB Test - Video vs Logo Soup
  noVideo = false,
  // Remove references to cable
  removeCable,
  // Charter Plan Cards
  hasCharterPlanCards,
  // Optimum Plan Cards
  hasOptimumPlanCards,
  // Pro Plan Cards
  hasProPlanCards,
  // Essential Plan Cards
  hasEssentialPlanCards,
  // AB Test for MatchTicker
  matchtickerSportId,
  matchtickerLeagueName,
  matchtickerLeagueId,
  // Holiday Promo
  showHoliday,
  // Disable Free Trial
  disableFreeTrial,
  // AB test New Template
  newTemplate,
  testTemplate,
  // CA Redirect URL
  caRedirectUrl,
  showAddOn,
  // Personalization IDs
  sportId,
  leagueId,
  teamId,
  // FanDuel Plan Cards
  hasFanduelPlanCards,
  fanduelRSNData,
  showFDOnly,
  // Additional FAQs
  additionalFAQs,
  platformImage,
  dealSavingsText,
}) => {
  // Disable Free Trial Modifications
  const modifiedCtaText = ctaText
  const promoBannerUS = useSelector(selectPromoBannerUSFlag)
  const showPromoBadge = useSelector(selectPromoBadgeFlag)
  const isCohortReady = useSelector(selectStacFlagsReady)
  const [isPromoBadgeRendered, setIsPromoBadgeRendered] = useState(false)
  const showValuePropsHero = useSelector(selectValuePropsHeroFlag)
  disableFreeTrial = disableFreeTrial ?? !isUSFreeTrial
  const isMatchTickerPlacement = useSelector(selectMatchTickerPlacementvNoneFlag)
  const modifiedNetworkSubtitle =
    networkSubtitle ||
    (disableFreeTrial && (lang === 'es' ? 'Cancela cuando quieras.' : 'Cancel online anytime.'))
  let metaDescription =
    pageDescription ||
    'Stream live TV with ABC, CBS, FOX, NBC, ESPN & top channels without cable. DVR included. No contract, cancel anytime.'

  if (team) {
    metaDescription = `Stream ${team} live online on ABC, CBS, FOX, NBC, and ESPN with Fubo. No contract is required - try it for free and cancel anytime.`
  }

  const stateForProtobuf = useSelector(selectStateForProtobuf)

  const handleLocaleSwitch = () => {
    fireUIInteractionEvent(
      {
        element: noteUrl,
        component: 'lp_locale_switch_clicked',
      },
      stateForProtobuf
    )
  }

  const originalParams = useSelector(selectAnalyticsParameters)
  let noteUrl
  if (noteLink) {
    noteUrl = createUrl(noteLink, originalParams)
  }

  let noteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        To learn more in English, <span>click here</span>.
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Para traducir a español, <span>haga clic aquí</span>.
      </Link>
    )

  let charterNoteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        English
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Español
      </Link>
    )

  const hasAnchor =
    hasFanduelPlanCards || hasEssentialPlanCards || hasProPlanCards ? '#select-plan' : null
  const hasOffer = offerBanner || offerCode

  const heroFootnote =
    !disableFreeTrial && showValuePropsHero && 'Free trial | No contract required | Cancel anytime'

  if (hasFanduelPlanCards || offerBanner === ' ') {
    offerBanner = null
  } else if (!hasOffer) {
    if (promoBannerUS !== 'default') {
      offerBanner = promoBannerUS
    }
    if (fanduelRSNData?.offerCode) {
      offerCode = fanduelRSNData.offerCode
    }
  }

  useEffect(() => {
    if (!isCohortReady) return
    if (showPromoBadge && !isPromoBadgeRendered && !hasOffer) {
      fireUIRenderEvent(
        {
          element: 'Special offer: $20 off one month',
          component: 'promo_badge',
        },
        stateForProtobuf
      )
      setIsPromoBadgeRendered(true)
    }
  }, [showPromoBadge, isCohortReady, isPromoBadgeRendered])

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      addonCode={addonCode}
      ctaHrefOverride={hasAnchor}
      noRedirect={noRedirect}
      miceText={miceText}
      caRedirectUrl={caRedirectUrl}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <LogoSyncContextProvider annotations={annotations}>
        <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
        {matchtickerLeagueId && isMatchTickerPlacement === 'test-above' && (
          <>
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
              ctaText={modifiedCtaText}
              disableFreeTrial={disableFreeTrial}
            />
          </>
        )}

        <HeroSectionNew
          logo={logo}
          logoHeight={logoHeight}
          lang={lang}
          market={market}
          heroBgVideo={video}
          heroBgVideoMobile={videoMobile}
          title={title}
          subtitle={subtitle}
          ctaText={modifiedCtaText}
          staticAnnotation={staticAnnotation}
          posterImg={poster}
          posterImgMobile={posterMobile}
          noteText={heroCtaNote}
          hasOfferInput={hasOfferInput}
          noVideo={noVideo}
          ctaHrefOverride={hasAnchor}
          offerCode={offerCode}
          addonCode={addonCode}
          footnote={heroFootnote}
          disableFreeTrial={disableFreeTrial}
        />

        {matchtickerLeagueId && isMatchTickerPlacement === 'test-below' && (
          <>
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
              ctaText={modifiedCtaText}
              disableFreeTrial={disableFreeTrial}
            />
          </>
        )}
        {showRSN && (
          <RSNGeolocationBannerNew
            hasOfferInput={hasOfferInput}
            ctaText={modifiedCtaText}
            ctaHrefOverride={hasAnchor}
            disableFreeTrial={disableFreeTrial}
            lang={lang}
            market={market}
          />
        )}
        {showRedzone && (
          <RedzoneBanner
            redzoneOfferCode={redzoneOfferCode}
            ctaText={modifiedCtaText}
            disableFreeTrial={disableFreeTrial}
          />
        )}

        {highlightImage && (
          <HighlightSection
            image={highlightImage}
            title={highlightHeadline}
            subtitle={highlightSubtitle}
            miceText={highlightNote}
            background={highlightBackground}
            showHeadlineTop={highlightShowHeadlineTop}
            disableFreeTrial={disableFreeTrial}
            market={market}
            lang={lang}
          />
        )}
        {highlightNBA && <HighlightNBA />}
        {hasFanduelPlanCards && (
          <FanduelPlanCards showFDOnly={showFDOnly} fanduelRSNData={fanduelRSNData} />
        )}
        {hasEssentialPlanCards && <EssentialPlanCards ctaText={modifiedCtaText} />}
        {hasProPlanCards && <ProPlanCards ctaText={modifiedCtaText} />}
        {hasCharterPlanCards && (
          <CharterPlanCards lang={lang} market={market} ctaText="Start Your Free Trial" />
        )}
        {hasOptimumPlanCards && (
          <OptimumPlanCards lang={lang} market={market} ctaText="Start Your Free Trial" />
        )}
        {/* {hasPlanCards && <USPlanCards lang={lang} market={market} />} */}
        {!hasCharterPlanCards && (
          <NetworkSectionNew
            lang={lang}
            market={market}
            headline={networkHeadline}
            subtitle={modifiedNetworkSubtitle}
            networkSet={networkSet}
            customNetworks={customNetworks}
            ctaText={modifiedCtaText}
            hasOfferInput={hasOfferInput}
            ctaHrefOverride={hasAnchor}
            disableFreeTrial={disableFreeTrial}
          />
        )}
        <ChannelSection
          lang={lang}
          market={market}
          title={
            removeCable &&
            (lang === 'es'
              ? 'Disfruta de los deportes, programas, noticias y películas que te encantan.'
              : 'Enjoy the sports, shows, news and movies you love')
          }
          initialChannelCount={hasCharterPlanCards && 116}
        />
        {showAddOn && <AddOnsSection />}
        <DealSectionNew
          lang={lang}
          market={market}
          title={
            removeCable && (lang === 'es' ? '¿Por qué elegir Fubo?' : 'Why you should choose Fubo')
          }
          savingsText={dealSavingsText}
        />
        <PlatformSectionNew
          lang={lang}
          market={market}
          ctaText={modifiedCtaText}
          hasOfferInput={hasOfferInput}
          ctaHrefOverride={hasAnchor}
          platformImage={platformImage}
          disableFreeTrial={disableFreeTrial}
        />
        <ExtraCTABanner
          hasOfferInput={hasOfferInput}
          ctaText={modifiedCtaText}
          ctaHrefOverride={hasAnchor}
          market={market}
          lang={lang}
          disableFreeTrial={disableFreeTrial}
        />
        <FAQSectionNew
          lang={lang}
          market={market}
          team={team}
          removeCable={removeCable}
          additionalFAQs={additionalFAQs}
        />
      </LogoSyncContextProvider>
    </Layout>
  )
}

export default USTemplateDefault
