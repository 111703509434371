import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { faqs, newFAQCopyEn } from 'src/constants/faqs'
import { useSelector } from 'react-redux'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { selectFAQMessagingFlag } from 'src/modules/reducers/cohorts'

const FAQSection = ({
  lang = 'en',
  market = 'us',
  team,
  additionalFAQs,
  foxRedirect,
  removeCable,
  disableFreeTrial,
  canadaEnt,
}) => {
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const faqMessagingFlag = useSelector(selectFAQMessagingFlag)
  const [activeFaqsList, setActiveFaqsList] = useState([])
  const [openIndexes, setOpenIndexes] = useState([])
  const isUsAndEng = market == 'us' && lang == 'en'
  const showNewFAQContent = isUsAndEng && faqMessagingFlag === 'test'
  useEffect(() => {
    let faqsList = [...faqs[market][lang]].map(faq => ({ ...faq }))
    if (showNewFAQContent) {
      faqsList = newFAQCopyEn
    }

    if (additionalFAQs) {
      faqsList.splice(1, 0, ...additionalFAQs)
    }

    setOpenIndexes(Array(faqsList.length).fill(false))
    setActiveFaqsList(faqsList)
  }, [lang, market, showNewFAQContent, additionalFAQs])

  const toggleFAQ = index => {
    // Toggle the state for only the clicked item
    const updatedIndexes = openIndexes.map((item, idx) => {
      return index === idx ? !item : item
    })
    setOpenIndexes(updatedIndexes)
    // Send analytics
    fireUIInteractionEvent(
      {
        element: activeFaqsList[index].question,
        type: showNewFAQContent ? 'new' : 'old',
        component: 'faq_item_clicked',
      },
      stateForProtobuf
    )
  }

  const title = lang === 'es' ? 'Preguntas Frecuentes' : 'Frequently Asked Questions'

  const inquiry =
    lang === 'es' ? (
      <>
        Para cualquier duda o pregunta, visita nuestro{' '}
        <a href="https://support.fubo.tv/hc/es" target="_blank" rel="noreferrer">
          Centro de Ayuda
        </a>
      </>
    ) : (
      <>
        Still have questions? Visit our{' '}
        <a href="https://support.fubo.tv/" target="_blank" rel="noreferrer">
          Help Center
        </a>{' '}
        to find more answers and contact us.
      </>
    )

  const faqFooterText = {
    en: {
      headline: 'Still have questions? Expert help, just a click away.',
      inquiry: (
        <>
          For technical questions or getting help with your account, visit our{' '}
          <a href="https://support.fubo.tv/" target="_blank" rel="noreferrer">
            Help Center
          </a>{' '}
          to find more answers and contact us.
        </>
      ),
      footnote:
        'Agent hours of availability: 8:00 AM - 11:00PM ET - 7 days a week. Holiday hours may vary.',
    },
    es: {
      headline: '¿Tienes alguna pregunta? Nuestro equipo de ayuda está a tu disposición.',
      inquiry: (
        <>
          Para soporte técnico o ayuda con tu cuenta, visita nuestro{' '}
          <a href="https://support.fubo.tv/" target="_blank" rel="noreferrer">
            Centro de Ayuda
          </a>
        </>
      ),
      footnote:
        'Horario de atención: De 8:00 AM a 11:00 PM (ET), todos los días. Los horarios en días festivos pueden variar.',
    },
  }

  const halfwayIndex = Math.ceil(activeFaqsList.length / 2)
  const column1 = activeFaqsList.slice(0, halfwayIndex)
  const column2 = activeFaqsList.slice(halfwayIndex)

  const renderFAQColumn = (column, offset) => (
    <div className="faq-column">
      {column.map((faq, index) => {
        const absoluteIndex = index + offset
        return (
          <li key={absoluteIndex} className="faq-item" onClick={() => toggleFAQ(absoluteIndex)}>
            <div className="faq-question">
              <h4>{faq.question}</h4>
              <div className={openIndexes[absoluteIndex] ? 'faq-icon visible' : 'faq-icon'}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9393 17.5607C11.5251 18.1464 12.4749 18.1464 13.0607 17.5607L21.3107 9.31066C21.8964 8.72487 21.8964 7.77513 21.3107 7.18934C20.7249 6.60355 19.7751 6.60355 19.1893 7.18934L12 14.3787L4.81066 7.18934C4.22487 6.60355 3.27513 6.60355 2.68934 7.18934C2.10355 7.77513 2.10355 8.72487 2.68934 9.31066L10.9393 17.5607Z"
                    fill="#1877FF"
                  />
                </svg>
              </div>
            </div>
            <div className={openIndexes[absoluteIndex] ? 'faq-answer visible' : 'faq-answer'}>
              <p>{faq.answer}</p>
            </div>
          </li>
        )
      })}
    </div>
  )

  return (
    <StyledFAQSection>
      <div className="faq-wrapper">
        <div className="faq-heading">
          <h2>{title}</h2>
        </div>
        <div className="faq-body">
          <ul className="faq-content">
            {renderFAQColumn(column1, 0)}
            {renderFAQColumn(column2, halfwayIndex)}
          </ul>
        </div>
        <div className="faq-footer">
          <div className="faq-footer-head">
            <h3>{faqFooterText[lang].headline}</h3>
          </div>
          <div className="faq-inquiry">{faqFooterText[lang].inquiry}</div>

          <div className="faq-footnote">{faqFooterText[lang].footnote}</div>
        </div>
      </div>
    </StyledFAQSection>
  )
}

export default FAQSection

const breakpoint = 1000
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

// 3:2 Figma to Mobile (calculate 2/3 of figma sizing for mobile)

const StyledFAQSection = styled.section`
  background: #fff;
  overflow: hidden;

  a {
    color: #1877ff;
    text-decoration-line: underline;
  }

  .faq-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .faq-heading {
    padding: 40px 20px;
    text-align: center;

    @media (min-width: ${desktopBreak}) {
      padding: 86px 20px 65px;
    }

    h2 {
      text-align: center;

      font-size: 25px;
      font-weight: 800;
      line-height: 1.1;
      color: #040911;

      @media (min-width: ${desktopBreak}) {
        font-size: 36px;
      }
    }
  }
  .faq-body {
    width: 100%;
    .faq-content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      padding: 0 20px;
      @media (min-width: ${desktopBreak}) {
        grid-template-columns: 1fr 1fr;
        gap: 45px;
        padding: 0 30px;
      }

      .faq-column {
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media (min-width: ${desktopBreak}) {
          gap: 45px;
        }
      }

      .faq-item {
        border-radius: 14px;
        background: #fafafa;
        padding: 27px 39px;
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;

        @media (min-width: ${desktopBreak}) {
          border-radius: 20px;
          padding: 40px 58px;
        }

        .faq-question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          h4 {
            color: #000;

            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            @media (min-width: ${desktopBreak}) {
              font-size: 19px;
            }
          }
          .faq-icon {
            display: flex;

            &.visible {
              transform: rotate(180deg);
            }
          }
        }

        .faq-answer {
          padding-right: 22px;
          max-height: 0;
          overflow: hidden;
          @media (min-width: ${desktopBreak}) {
            padding-right: 34px;
          }
          p {
            color: #000;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.4;
            margin: 14px 0 0;
            @media (min-width: ${desktopBreak}) {
              line-height: 1.96;
              margin: 20px 0 0;
            }
          }

          &.visible {
            display: block;
            max-height: 600px;
            transition: max-height 0.8s;
          }
        }
      }
    }
  }
  .faq-footer {
    padding: 0 20px;
    @media (min-width: ${desktopBreak}) {
      padding: 0 30px;
    }
    .faq-footer-head {
      h3 {
        color: #121318;
        text-align: center;

        font-size: 25px;
        font-weight: 800;
        line-height: 1.1;
        margin-top: 100px;
        margin-bottom: 38px;

        @media (min-width: ${desktopBreak}) {
          font-size: 36px;
          margin-top: 150px;
          margin-bottom: 56px;
        }
      }
    }
    .faq-inquiry {
      border-radius: 14px;
      background: #fafafa;
      padding: 27px 39px;
      width: 100%;
      box-sizing: border-box;

      color: #000;
      text-align: center;

      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;

      @media (min-width: ${desktopBreak}) {
        border-radius: 20px;
        padding: 40px 58px;
      }
    }
    .faq-footnote {
      color: #aaacb2;
      text-align: center;

      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      margin-top: 14px;
      margin-bottom: 46px;
      @media (min-width: ${desktopBreak}) {
        margin-top: 20px;
        margin-bottom: 70px;
      }
    }
  }
`
