import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'
import { isUSFreeTrial } from 'src/modules/utils/constants'

export const youtubeData = {
  competitor: 'YouTube TV',
  competitorColor: '#FFFFFF',
  heroData: {
    title: 'Looking for the best streaming service?',
    subtitle:
      'Compare Fubo & YouTube TV on price, features, channels and add-ons to see which is better for you.',
  },
  generalCompareData: {
    heading: 'Compare Fubo vs. YouTube TV',
    subheading:
      'Here’s a general look at both to help you determine which is the best streaming service for you.',
    listItems: ['Channel Count', 'English Plans', 'Simultaneous Streams', 'Starting Price'],
    fuboList: ['185+', '3', 'Up to 10', '$84.99/mo.*'],
    competitorList: ['100+', '1', '3', '$72.99/mo.*'],
  },
  prosConsData: {
    heading: 'Pros and Cons: Fubo vs. YouTube TV',
    content: (
      <>
        <p>
          When it comes to cord cutting, Fubo and YouTube TV are two of the best streaming services
          around. Both are substantially cheaper than cable and offer many of the same great live
          channels for live sports and popular entertainment. When it comes to picking between the
          two, it comes down to inches in terms of price, channels available, and features.
        </p>
        <p>
          Fubo is a more premium product and it has the price tag to match. Fubo plans are loaded
          with live channels (at least 185 live channels) and quality features. If you compare the
          channels available on Fubo vs. YouTube TV, Fubo has an edge in local sports coverage with
          their regional sports networks. Plus, Fubo has an extensive video-on-demand library. While
          most streaming services have VOD content, Fubo has more than 40K TV shows and movies
          available via VOD each month as well as 55K live sporting events on the platform annually.
        </p>
        <p>
          YouTube TV compares to Fubo in a lot of the same ways, even the price isn’t that much
          cheaper. YouTube TV comes with noticeably less channels and they have a big hole in their
          local sports coverage. By basically abandoning the inclusion of regional sports networks,
          YouTube TV has denied many within the local markets access to their NBA, MLB and NHL
          teams’ games. They do have the WarnerMedia networks. The biggest issue for YouTube TV is
          just the lack of options. They only have the one English plan, and your only plan upgrade
          option is whether to get the expensive NFL Sunday Ticket bundle or not.
        </p>
      </>
    ),
  },
  plansData: {
    heading: 'Compare Fubo Pro plan vs. YouTube TV',
    subheading:
      'Let’s take a look at the differences between the popular Fubo Pro plan and a basic YouTube TV account.',
    tableData: {
      Plan: ['Pro plan', 'base'],
      Channels: ['185+', '100+'],
      'Monthly Price': ['$84.99', '$72.99'],
      'Hours of DVR storage': ['Unlimited', 'Unlimited'],
      'Free Trial': [isUSFreeTrial ? 'Yes' : 'No', 'Yes'],
      'Multiple active streams': ['Up to 10', 'Up to 3'],
      'Regional sports networks': [true, true],
      'Streaming from any device': [true, true],
    },
  },
  channelsData: {
    heading: 'Compare Fubo Channels vs. YouTube TV Channels',
    subheading:
      'One of the biggest differences between Fubo and YouTube TV is with their live sports channels. This chart compares which sports networks, including regional sports networks only accessible in select markets, are available overall with Fubo and YouTube TV.',
    networksData: [
      { network: networkLogos.nflnetwork, fubo: true, competitor: true },
      { network: networkLogos.mlbnetwork, fubo: true, competitor: false },
      { network: networkLogos.nbatv, fubo: true, competitor: true },
      { network: networkLogos.nhlnetwork, fubo: true, competitor: false },
      { network: networkLogos.espn, fubo: true, competitor: true },
      //REMOVED: univision_removed tudn
      // { network: networkLogos.tudn, fubo: true, competitor: true },
      { network: networkLogos.sec, fubo: true, competitor: true },
      { network: networkLogos.acc, fubo: true, competitor: true },
      { network: networkLogos.bigten, fubo: true, competitor: true },
      // { network: networkLogos.pac12, fubo: true, competitor: false },
      { network: networkLogos.fs1, fubo: true, competitor: true },
      { network: networkLogos.yesnetwork, fubo: true, competitor: false },
      { network: networkLogos.nesn, fubo: true, competitor: false },
      { network: networkLogos.fanduelsports, fubo: true, competitor: false },
      { network: networkLogos.masn, fubo: true, competitor: false },
    ],
  },
  completedChannelsData: {
    heading: 'Complete Channel Comparison',
    subheading: 'See how the live sports channel lineups compare between Fubo and YouTube TV.',
    content: (
      <>
        <p>
          When comparing channel lineups for Fubo and YouTube TV, one of the biggest differences
          between the two comes down to the sports channels. With major league networks like MLB
          Network and the regional sports networks in each local market, Fubo has more sports
          channels overall.
        </p>
        <p>
          Fubo has the most local sports for the lowest price as well as great local network
          coverage for ABC, CBS, FOX and NBC. This makes Fubo one of the best streaming services for
          cord cutters who want to keep their local channels. They have a lot of the most popular
          networks in their lineup. Besides having regional sports networks like YES Network, NESN,
          FanDuel Sports, NBC Sports and MASN in select local markets, Fubo also has MLB Network and
          NHL Network available through plans and add-ons.
        </p>
        <p>
          YouTube TV also has a good lineup of live channels. They have many of the same channels
          available on Fubo with a few key differences. YouTube TV lacks some of the major league
          sports networks like MLB Network but does have WarnerMedia networks. YouTube TV only has a
          few regional sports networks.
        </p>
      </>
    ),
  },
  faqData: {
    qaData: [
      {
        id: 'fubo-faq-5',
        question: 'What channels does Fubo have vs. YouTube TV?',
        answer: (
          <p>
            The channels Fubo has over YouTube TV include MLB Network, NHL Network & more. Fubo also
            has local sports coverage on YES Network, NESN, FanDuel Sports, NBC Sports & MASN in
            select local markets.
          </p>
        ),
      },
    ],
  },
}
