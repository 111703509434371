import React from 'react'
import styled from 'styled-components'
import { defaultCTAURI, USDefaultCtaTextShort } from 'src/modules/utils/constants'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import CTAButton from 'src/components/common/CTAButtonNew'
import { getCtaText } from 'src/modules/utils/language/buttons'

const ExtraCTABanner = ({
  market,
  lang,
  ctaHrefOverride,
  ctaQuery,
  hasOfferInput,
  ctaText,
  hidden,
  disableFreeTrial = false,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'extra_cta_banner')
  const defaultCtaText = ctaText || getCtaText({ lang, market, disableFreeTrial })
  const extraCTAText = {
    en: {
      headline: 'Ready to start streaming?',
      subtitle: (
        <>
          {' '}
          We’ll remind you the day before your trial ends.{' '}
          <span style={{ display: 'inline-block' }}>Watch TV, not the clock.</span>
        </>
      ),
    },
    es: {
      headline: '¿Listo para empezar a disfrutar?',
      subtitle:
        'Te avisaremos un día antes de que termine tu prueba gratuita. Disfruta de tu contenido y TV favorita sin preocupaciones.',
    },
  }
  return (
    <StyledExtraCTABanner>
      <div className="banner-content">
        <div className="banner-text">
          <h3>{extraCTAText[lang].headline}</h3>
          <h5>{extraCTAText[lang].subtitle}</h5>
        </div>
        <CTAButton link={hasOfferInput ? '#' : ctaHrefOverride || ctaURL}>
          {defaultCtaText}
        </CTAButton>
      </div>
    </StyledExtraCTABanner>
  )
}

export default ExtraCTABanner

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

const StyledExtraCTABanner = styled.div`
  background: linear-gradient(180deg, #FF3A03 52.95%, #E3001B 100.21%);
  width: 100%;
  text-align: left;
  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
    flex-direction: column;
    gap: 30px;
    padding: 40px 20px 53px;

    @media (min-width: ${desktopBreak}) {
      padding: 75px 40px 80px;
      gap: 20px;
      flex-direction: row;
    }

    .banner-text {
      color: #fff;
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 18px;
      text-align: center;
      padding: 0;
      ${'' /* margin-bottom: 33px; */}
      @media (min-width: ${desktopBreak}) {
        text-align: left;
        align-items:  flex-start;
        ${'' /* padding: 10px 10px; */}
        ${'' /* gap: 8px; */}
        ${'' /* margin-bottom: 0; */}
        ${'' /* flex: 0 1 350px; */}
      }
      h3 {
        color: #FFF;
        text-align: center;
    
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 1.17;
        @media (min-width: ${desktopBreak}) {
          font-size: 62px;
          text-align: left;
        }
      }
      h5 {
        color: #fff;
        text-align: center;
    
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.33;
        margin:0;
        @media (min-width: ${desktopBreak}) {
          font-size: 23px;
          text-align: left;
        }
      }
    }
    .CTAButton {
      background: #fff;
      color: #000;
      flex: 0 0 auto;
      ${'' /* font-size: 17px; */}
      @media screen and (min-width: ${desktopBreak}) {
        ${'' /* font-size: 20px; */}
      }
    }
  }
`
