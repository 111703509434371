export const ratePlanCodes = {
  canada_ent_mo: "ca-recu-ga-entertainment-mo-7d-v1",
  canada_ent_qt: "ca-recu-ga-entertainment-qt-7d-v1",
  canada_premium_mo: "ca-recu-ga-premium-mo-nt-v2",
  canada_premium_qt: "ca-recu-ga-premium-qt-nt-v1",
  canada_premium_yr: "ca-recu-ga-premium-yr-nt-v2",
  canada_sports_mo: "ca-recu-ga-sports-mo-nt-v1",
  canada_sports_qt: "ca-recu-ga-sports-qt-nt-16amtoff1-v1",
  canada_sports_yr: "ca-recu-ga-sports-yr-nt-63amtoff1-v1",
  elite: "us-recu-ga-elite-mo-5d-20amtoff1-v1",
  essential: "us-recu-ga-essential-mo-5d-25amtoff1-v1",
  latino: "us-recu-ga-latino-mo-5d-5amtoff1-v1",
  pro: "us-recu-ga-pro-mo-5d-20amtoff1-v1"
}