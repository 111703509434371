// web

export const WEB_MARKETING_FAQ_MESSAGING = 'web:marketing-faq-messaging-v2'
export const WEB_MARKETING_FANDUEL_PLAN_CARDS = 'web:marketing-fanduel-plan-cards'
export const WEB_MARKETING_ADD_ONS_SECTION = 'web:marketing-add-ons-section'
export const WEB_MARKETING_PROMO_BANNER_US = 'web:marketing-promo-banner-us'
export const WEB_MARKETING_INCLUDE_CRTC = 'web:marketing-include-crtc-v2'
export const WEB_MARKETING_MATCHTICKER_PLACEMENT_V_NONE =
  'web:marketing-matchticker-placement-v-none'
export const WEB_MARKETING_PROMO_BADGE = 'web:marketing-promo-badge'
export const WEB_MARKETING_CTA_COPY_CONTINUE = 'web:marketing-cta-copy-continue'
export const WEB_MARKETING_VALUE_PROPS_HERO = 'web:marketing-value-props-hero'
