import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/SEM_Warriors_1280x720_LP_15s.mp4'}
    staticAnnotation={networkLogos.nbcsbayarea}
    title="Watch Golden State basketball live"
    subtitle="STREAM THE WARRIORS ON NBC SPORTS BAY AREA"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    ctaText={'Learn More'}
    networkSet={'basketball'}
    customNetworks={['nbcsbayarea']}
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
    teamId={teamIds['Golden State Warriors']}
  />
)
