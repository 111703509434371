import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { promoCodes } from 'src/constants/promoCodes'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.seasonal_us.videoUrl}
    videoMobile={videos.seasonal_us.videoMobileUrl}
    annotations={videos.seasonal_us.annotations}
    title="Stream Live TV & Sports"
    subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
    tagline="Watch and DVR the channels you love. No contracts, no commitment."
    showRSN={true}
    offerCode={promoCodes.crunch}
    offerBanner={
      <>
        ✔ Special offer for Crunch members activated!
        <br />
        Enjoy 15% off your first month of Fubo Pro. Try it free today.
      </>
    }
    logoHeight={'90px'}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-crunch-2.png?w=451&ch=width&auto=format,compress"
  />
)
