import React from 'react'
import { laChannelCount } from './laPlanData'

export const faqs = {
  us: {
    en: [
      {
        id: 'what-is-fubo',
        question: 'What is Fubo?',
        answer:
          'Fubo is a service that broadcasts live TV over the internet, no cable required. Watch your favorite teams, network shows, news and movies on 200+ channels. Plus on-demand entertainment including full TV series.',
      },
      {
        question: 'Can I watch soccer leagues live with Fubo?',
        answer:
          'Yes! Fubo networks broadcast Premier League, Champions League, MLS, Ligue 1, Liga MX and many others leagues and tournaments.',
      },
      {
        id: 'nfl',
        question: 'How can I watch the NFL online without cable?',
        answer:
          "To watch the NFL without cable, just start a free trial of Fubo. You can start watching immediately on your TV, phone, computer and more. There's no contract and no commitment.",
      },
      {
        question: 'Does Fubo have channels like ABC, CBS, FOX and NBC?',
        answer:
          'Yes, Fubo has ABC*, CBS*, FOX* and NBC* as well as ESPN, MTV, VH1, SHOWTIME, MSNBC, FX, USA, Comedy Central, E! and Disney Channel among others. *Regional restrictions apply.',
      },
      {
        question: 'What are some shows I can watch on Fubo?',
        answer:
          "Fubo gives you live and on-demand access to top shows like Survivor, The Simpsons, Abbott Elementary, Bob's Burgers, The Bachelor, Shark Tank, 9-1-1, Yellowstone, Saturday Night Live, Tucker Carlson Tonight, The Rachel Maddow Show, The Conners, Family Guy, Atlanta, The Masked Singer, Law & Order: SVU, Billions…the list keeps growing.",
      },
      {
        question: 'Are leagues like the NFL, NBA, MLB and others available?',
        answer:
          'Absolutely! Not only does Fubo have the NFL, NBA and MLB, but you can also watch NHL, NASCAR, MLS, golf, tennis, boxing, MMA, college sports and more. Plus, we have every golf major, tennis major and Triple Crown race as well as full coverage of specials like the Super Bowl, World Series, NBA Finals, Stanley Cup Playoffs and Olympics.',
      },
      {
        question: 'Is Fubo available where I live?',
        answer:
          'Fubo is currently available in the United States, Canada and Spain. Packages and pricing differ per country.',
      },
      {
        id: 'college-football',
        question: 'How can I watch college football online without cable?',
        answer:
          "To watch college football without cable, just start a free trial of Fubo. You can start watching immediately on your TV, phone, computer and more. There's no contract and no commitment.",
      },
      {
        question: 'Can I record games & shows?',
        answer:
          'Yes, all U.S. Fubo plans include unlimited Cloud DVR space for recording all your favorite sports, shows, movies & news at no extra charge. Hit record on one device and watch that recorded content on another. Recorded content will be available in your library for nine months.',
      },
      {
        id: 'how-much',
        question: 'How much does Fubo cost?',
        answer:
          'Prices vary based on your plan, region and selected add-ons. Our most popular plan in the United States includes 200+ channels of live sports and TV for half the cost of the average monthly cable bill.',
      },
      {
        question: 'Do I have to sign a contract?',
        answer:
          'No. Fubo is strictly on a month-to-month or quarterly basis. Your subscription will automatically renew until you choose to cancel your account in your profile.',
      },
      {
        question: 'What if I want to cancel?',
        answer:
          "We’d hate to see you go, but we understand. That's why we've made it easy. Cancel online anytime without paying fees.",
      },
      {
        question: 'What devices can I watch Fubo on?',
        answer:
          'You can watch Fubo on your computer, iPhone, iPad, Android phone and tablet, Roku, Apple TV, Chromecast, Xbox consoles and Amazon Fire TV.',
      },
      {
        question: 'Can I stream shows on my TV?',
        answer:
          'Yes. The easiest way to watch Fubo on your TV is with a Roku, Apple TV, Amazon Fire TV or Chromecast. Simply install the Fubo app on your device to stream on your TV.',
      },
      {
        question: 'How many devices can I simultaneously watch on?',
        answer:
          'Fubo can be actively streamed on two devices at the same time. Need a third stream? Add Family Share to your account for an additional $5.99/mo.',
      },
      {
        question:
          'After signing up, where can I find help if I have questions about my account/service?',
        answer: (
          <>
            For technical questions or getting help with your account, visit the{' '}
            <a href="https://support.fubo.tv/" target="_blank" rel="noreferrer">
              Fubo Help Center
            </a>{' '}
            to find answers and contact us.
          </>
        ),
      },
    ],
    es: [
      {
        id: 'what-is-fubo',
        question: '¿Qué es Fubo?',
        answer:
          'Fubo es un servicio que transmite televisión en vivo por internet, sin requerimiento del servicio de cable tradicional. Puedes ver tus equipos favoritos, shows, series, noticias y películas de más de 200 canales. Y además contenido on-demand, incluyendo series completas.',
      },
      {
        question: '¿Puedo ver las ligas de fútbol online y en vivo con Fubo?',
        answer:
          '¡Si! Los canales de Fubo emiten la Premier League, Champions League, Bundesliga, MLS, Ligue 1, Liga MX, Eliminatorias CONMEBOL y muchas otras ligas y torneos.',
      },
      {
        id: 'nfl',
        question: '¿Cómo puedo ver la NFL online sin cable?',
        answer:
          'Para ver la NFL sin cable, simplemente tienes que comenzar tu periodo de prueba gratis en Fubo. Una vez hecho esto, podrás empezar inmediatamente a disfrutar desde tu celular, TV, computadora y más. No hay contrato de permanencia.',
      },
      {
        question: '¿Ofrece Fubo canales como ABC, CBS, FOX and NBC?',
        answer:
          'Sí, Fubo cuenta con ABC*, CBS*, FOX* y NBC* así como con ESPN, MTV, VH1, SHOWTIME, MSNBC, FX, USA, Comedy Central, E! y Disney Channel entre otros muchos canales. *Se aplican algunas restricciones regionales.',
      },
      {
        question: '¿Qué shows y series puedo ver en Fubo?',
        answer:
          "Con Fubo tendrás acceso a top shows en vivo y on-demand como Survivor, The Simpsons, Abbott Elementary, Bob's Burgers, The Bachelor, Shark Tank, 9-1-1, Yellowstone, Saturday Night Live, Tucker Carlson Tonight, The Rachel Maddow Show, The Conners, Family Guy, Atlanta, The Masked Singer, Law & Order: SVU, Billions…y muchos más.",
      },
      {
        question: '¿Puedo ver ligas y competiciones con NFL, NBA, MLB y otras?',
        answer:
          '¡Por supuesto! Fubo no solo ofrece la NFL, NBA y MLB, sino que también puedes ver NHL, NASCAR, MLS, golf, tenis, boxeo, MMA, competiciones universitarias y más. Además, contamos con todas las competiciones principales de golf, tenis y la carrera Triple Crown así como eventos especiales como la Super Bowl, World Series, NBA Finals, Stanley Cup Playoffs y los Juegos Olímpicos.',
      },
      {
        question: '¿Está Fubo disponible donde vivo?',
        answer:
          'Fubo está disponible en Estados Unidos, Canadá y España. Los paquetes de contenido y precio pueden variar dependiendo del país.',
      },
      {
        id: 'college-football',
        question: '¿ Cómo puedo ver el fútbol americano universitario sin cable?',
        answer:
          'Para ver el fútbol americano universitario sin cable, simplemente tienes que comenzar tu periodo de prueba gratis en Fubo. Una vez hecho esto, podrás empezar inmediatamente a disfrutar desde tu celular, TV, computadora y más. No hay contrato de permanencia.',
      },
      {
        question: '¿Puedo grabar los partidos y shows?',
        answer:
          'Sí, cada cuenta en Fubo incluye Cloud DVR (Nube de Grabadora digital) sin cargo adicional.',
      },
      {
        id: 'how-much',
        question: '¿Cúanto cuesta Fubo?',
        answer:
          'Los precios pueden variar dependiendo del plan, región y add-ons que se seleccionen. Nuestro plan más popular en Estados Unidos incluye más de 200 canales en vivo con deportes and TV por la mitad del precio de la factura mensual de cualquier compañía de cable.',
      },
      {
        question: '¿Tengo que firmar un contrato?',
        answer:
          'No, Fubo solo se cobra de forma mensual o trimestral. Tu suscripción se renovará de forma automática hasta que decidas cancelar tu cuenta en tu perfil.',
      },
      {
        question: '¿Qué pasa si decido cancelar?',
        answer:
          'Nos entristece que decidas cancelar, pero entendemos que puedas cambiar de opinión. Por eso hicimos el proceso tan sencillo. Cancela online en cualquier momento sin pagar ningún tipo de penalización.',
      },
      {
        question: '¿En qué dispositivos puedo reproducir Fubo?',
        answer:
          'Puedes ver Fubo en tu teléfono Android y tableta, computadora, iPhone, iPad, Roku, Apple TV, Chromecast, Xbox consoles y Amazon Fire TV.',
      },
      {
        question: '¿Puedo hacer streaming de los shows en mi TV?',
        answer:
          'Sí, puedes disfrutar de Fubo en tu televisor con Roku, Apple TV, Amazon Fire TV o Chromecast. Simplemente instala la app de Fubo en tu dispositivo para ver desde tu TV.',
      },
      {
        question: '¿En cuántos dispositivos puedo ver Fubo al mismo tiempo?',
        answer:
          'Con Fubo puedes ver en dos dispositivos de manera simultánea ¿Necesitas más? Agrega "Family Share Max" para ver en más pantallas por solo $5.99 al mes.',
      },
      {
        question:
          ' ¿Una vez que creo una cuenta, dónde puedo pedir ayuda si tengo dudas sobre mi cuenta o el servicio? ',
        answer: (
          <>
            Para cuestiones técnicas con tu cuenta, visita{' '}
            <a href="https://support.fubo.tv/" target="_blank" rel="noreferrer">
              {' '}
              Fubo Help Center{' '}
            </a>{' '}
            o llama a 1-844-441-3826.
          </>
        ),
      },
    ],
  },
  canada: {
    en: [
      {
        question: 'What is Fubo?',
        answer:
          'Fubo is a service that broadcasts live TV over the internet, no cable required. Watch live and on-demand sports, shows and movies anytime, anywhere, from any device.',
      },
      {
        question: 'How many devices can I simultaneously watch on?',
        answer: 'Fubo can be actively streamed on up to three devices at the same time.',
      },
      {
        question: 'Can I stream Fubo on my TV?',
        answer:
          'Yes, the easiest way to watch Fubo on your TV is with a Roku, Apple TV, Amazon Fire TV, Chromecast, Xbox consoles, LG and Hisense. Simply install the Fubo app to your device or smart TV to start streaming.',
      },
      {
        question: 'What if I want to cancel?',
        answer:
          "We'd hate to see you go, but we understand. That's why we've made it easy. Cancel online anytime without paying fees.",
      },
      {
        question: 'Can I record games & shows?',
        answer:
          'Yes, every Fubo account includes up to 1,000 hours of Cloud DVR space at no extra charge. You can hit record on one device and start watching on another.',
      },
      {
        question: 'What devices can I watch Fubo on?',
        answer:
          'You can watch Fubo on your computer, iPhone, iPad, Android phone, Android tablet, Roku, Apple TV, Xbox consoles, Chromecast, Amazon Fire TV, LG and Hisense.',
      },
      {
        id: 'soccer',
        question: 'Can I watch soccer leagues live with Fubo?',
        answer:
          'Yes! Fubo networks broadcast Premier League, Ligue 1, Serie A, Coppa Italia, Süper Lig, Canadian World Cup Qualifiers and many other leagues and tournaments.',
      },
      {
        question:
          'After signing up, where can I find help if I have questions about my account/service?',
        answer: (
          <>
            For technical questions or getting help with your account, visit the{' '}
            <a href="https://support.fubo.tv/" target="_blank" rel="noreferrer">
              Fubo Help Center
            </a>{' '}
            to find answers and contact us.
          </>
        ),
      },
    ],
  },
  latino: {
    en: [
      {
        question: 'What is Fubo?',
        answer:
          'Fubo is a service that transmits live television over the internet, without the requirement of traditional cable service. You can watch your favorite teams, shows, series, news and movies from any device.',
      },
      {
        question: 'Can I watch football leagues online and live with Fubo?',
        answer:
          //REMOVED: univision_removed
          //'Yes! The Fubo channels broadcast UEFA Champions League, Bundesliga, MLS, Ligue 1, Liga MX, CONMEBOL Qualifiers and many other leagues and tournaments in Spanish.',
          //REPLACED: univision_removed
          'Yes! Fubo has channels that broadcast live soccer in Spanish from LaLiga, Ligue 1, Serie A, Liga Portugal, Liga BetPlay Dimayor, Copa Libertadores and other leagues and tournaments from Argentina, Perú, Uruguay and other countries around the world.',
      },
      //REMOVED: univision_removed
      // {
      //   question: 'What shows and series can I watch on Fubo?',
      //   answer:
      //     'With Fubo Latino, you will have access to top series from Univision, UniMás and Galavisión, among other channels. The most popular series include La Rosa de Guadalupe, Nosotros Los Guapos, La Reina de la Canción, La Familia Peluche and many others added to the list.',
      // },
      {
        question: 'What other sports can I watch on Fubo?',
        answer:
          'NFL, NBA, NHL, MLB, NASCAR, golf, boxing, MMA, tennis, college sports, and many others. And we also give special coverage to special events such as the Super Bowl, World Series, Stanley Cup Playoffs, the Olympic Games, Triple Crown and UFC events.',
      },
      {
        question: 'What entertainment channels can I watch on Fubo?',
        //REMOVED: univision_removed
        // answer:
        //   'Fubo offers several TV packages with different entertainment options. The Fubo english-language base plan has FOX *, CBS *, NBC *, ABC *, ESPN, Telemundo, SHOWTIME®️, USA, E!, MTV and Hallmark Channel, among others. Fubo Latino includes Univision, Sony Cine, Estrella TV, Nat Geo Mundo and UniMás, among others. *Regional restrictions apply.',
        //REPLACED: univision_removed
        answer:
          'Fubo offers several TV packages with different entertainment options. The Fubo english-language base plan has FOX *, CBS *, NBC *, ABC *, ESPN, Telemundo, SHOWTIME®️, USA, E!, MTV and Hallmark Channel, among others. Fubo Latino includes Sony Cine, Estrella TV and Nat Geo Mundo, among others. *Regional restrictions apply.',
      },
      {
        question: 'Is Fubo available where I live?',
        answer:
          'The Fubo service is available in the United States, Canada, and Spain. Packages and prices differ depending on each country.',
      },
      {
        question: 'How much does Fubo cost?',
        answer: `Prices vary based on your plan, region and selected add-ons. Our most popular plan in the United States includes 200+ channels of live sports and TV for half the cost of the average monthly cable bill. Fubo Latino, our Spanish-language plan, has ${laChannelCount}+ channels of live sports and TV.`,
      },
      {
        question: 'Do I have to sign a contract?',
        answer:
          'No, your subscription will automatically renew until you decide to cancel your account in your profile.',
      },
      {
        question: 'On what devices can I play Fubo?',
        answer:
          'You can watch Fubo on your computer, iPhone, iPad, Android phone and tablet, Roku, Apple TV, Chromecast, Xbox consoles, and Amazon Fire TV.',
      },
      {
        question: 'Can I stream the shows on my TV?',
        answer:
          'Yes. The easiest way to watch Fubo on your TV is with a Roku, Apple TV, Amazon Fire TV or Chromecast. Simply install the Fubo app on your device to stream on your TV.',
      },
      {
        question: 'On how many devices can I watch Fubo at the same time?',
        answer:
          'Fubo can be viewed on three devices simultaneously with Fubo Latino. Do you need more? Add "Family Share Max" for an additional $9.99/mo.',
      },
      {
        question: 'Can I record the games and programs?',
        answer:
          'Yes, all U.S. Fubo plans include unlimited Cloud DVR space for recording all your favorite sports, shows, movies & news at no extra charge. Hit record on one device and watch that recorded content on another. Recorded content will be available in your library for nine months.',
      },
    ],
    es: [
      {
        question: '¿Qué es Fubo?',
        answer:
          'Fubo es un servicio que transmite televisión en vivo por internet, sin requerimiento de servicio de cable tradicional. Puedes ver tus deportes y equipos, programas, series, noticias y películas preferidas desde cualquier dispositivo.',
      },
      {
        question: '¿Puedo ver las ligas de fútbol online y en vivo con Fubo?',
        answer:
          '¡Sí! Los canales de Fubo emiten La Liga, Ligue 1, Serie A, la liga Portuguesa, la liga BetPlay de Colombia, y primeras divisiones de Argentina, Perú, Uruguay, y otros torneos internacionales como la Copa Libertadores, y mucho más.',
      },
      //REMOVED: univision_removed
      // {
      //   question: '¿Qué programas y series puedo ver en Fubo?',
      //   answer:
      //     'Con Fubo tendrás acceso a todas las series de Univision y UniMas entre otros canales. Las series más populares incluyen La Rosa de Guadalupe, Nosotros Los Guapos, La Reina de la Canción, La Familia Peluche y muchos otros que se añaden a la lista.',
      //}

      {
        question: '¿Está Fubo disponible donde yo vivo?',
        answer:
          'El servicio de Fubo está disponible en los Estados Unidos, Canadá y España. Los paquetes y los precios difieren dependiendo de cada país.',
      },
      {
        question: '¿Puedo grabar los partidos y programas?',
        answer:
          'Sí, Fubo incluye espacio ilimitado en el Cloud DVR para que puedas grabar tus deportes favoritos, shows, películas y noticieros. Programa la grabación y disfruta desde cualquiera de tus dispositivos. Todas la grabaciones se guardan en tu cuenta durante nueve meses.',
      },
      {
        question: '¿Cuanto cuesta Fubo?',
        answer: `Fubo Latino incluye más de ${laChannelCount} canales y se factura mensualmente a $33. Los precios varían con los planes y los paquetes adicionales.`,
      },
      {
        question: '¿Tengo que firmar un contrato?',
        answer:
          'No, Fubo se paga mensualmente, sin contratos ni permanencia. Tu suscripción se renovará automáticamente hasta que decidas cancelar tu cuenta con nosotros. Puedes cancelar fácilmente online.',
      },
      {
        question: '¿En qué dispositivos puedo reproducir Fubo?',
        answer:
          'Puedes ver Fubo en tu teléfono Android y tablet, computadora, iPhone, iPad, Roku, Apple TV, Chromecast y Amazon Fire TV.',
      },
      {
        question: '¿Puedo hacer streaming de los programas en mi TV?',
        answer:
          'Sí, puedes disfrutar de Fubo en tu televisor con Amazon Fire TV, Apple TV, Roku o Chromecast. Simplemente instala la app de Fubo en tu dispositivo para ver desde tu TV.',
      },
      {
        question: '¿En cuántos dispositivos puedo ver Fubo al mismo tiempo?',
        answer:
          'Con Fubo puedes ver en dos dispositivos de manera simultánea ¿Necesitas más? Agrega "Family Share Max" para ver en más pantallas por solo $5.99 al mes.',
      },
    ],
  },
  spain: {
    es: [
      {
        question: '¿Qué es Fubo?',
        answer:
          'Fubo es un servicio de TV por internet de recién lanzamiento en España que ofrece los principales canales de televisión y contenido a la carta de series, películas y programas, así como otros eventos deportivos.',
      },
      {
        question: '¿En qué tipo de dispositivos puedo ver Fubo?',
        answer:
          'Puedes ver Fubo en tu ordenador mac o windows, en el iPhone, iPad, en los teléfonos Android, en la videoconsola de Xbox y en las tabletas. También lo puedes ver en tu televisor a través de Chromecast y en las principales marcas de smartTV como LG.',
      },
      {
        question: '¿Cuánto es la cuota de suscripción a Fubo?',
        answer:
          'Todos los nuevos suscriptores disfrutarán de un periodo gratuito de prueba con todo el contenido de Fubo por 7 días. Tras este periodo te damos tres opciones económicas a elegir, 3,99€/mes con la suscripción anual, 4,99€/mes con la trimestral o 5,99€/mes con la suscripción mensual.',
      },
      {
        question: '¿Qué tipo de tarjetas de crédito puedo utilizar?',
        answer:
          'Aceptamos tarjetas de crédito y de débito Visa, MasterCard, Discover y American Express. No aceptamos tarjetas prepago para asegurar la seguridad de las transacciones.',
      },
      {
        question: '¿Tengo que firmar un contrato para ver el contenido?',
        answer:
          '¡Sin contrato y sin gastos de cancelación! Tu suscripción se renovará automáticamente cada mes hasta que decidas cancelarla. Puedes cancelarla en cualquier momento para que no se renueve una vez cumplido el periodo de suscripción elegido.',
      },
      {
        question: '¿Como puedo cancelar mi suscripción?',
        answer:
          'Cancelar tu suscripción a Fubo es muy sencillo – podrás hacerlo online desde “Mi cuenta” en la página web. No necesitas hacer ninguna llamada ni enviarnos ningún email.',
      },
      {
        question: 'Si tienes preguntas adicionales…',
        answer: (
          <>
            Si necesitas más información o para aclararte cualquier otra duda por favor escríbenos a{' '}
            <a href="mailto:ayuda@fubo.tv">ayuda@fubo.tv</a>
          </>
        ),
      },
    ],
  },
}

export const newFAQCopyEn = [
  {
    question: 'How is Fubo different from other live streaming services?',
    answer: (
      <>
        {' '}
        Fubo is different from other live streaming services in many important ways. For example,
        Fubo is built for live sports. We have over 55,000 sporting events per year, many in 4K.
        Plus, Fubo is the only live TV streaming service with every Nielsen-rated sports channel. We
        also have 35 regional sports networks, with most of them included in the lowest-cost base
        plan.
        <br />
        <br />
        Fubo also has the most popular entertainment and news channels so everyone in your home can
        watch what they love.
      </>
    ),
  },
  {
    question: 'Which leagues can I watch with Fubo?',
    answer: `You can watch games from every major league including the NFL, MLB, NBA, NHL and the most popular soccer leagues. Fubo also has live college football, F1, NASCAR, WNBA, MMA, golf, tennis, boxing and other high-demand sports. Plus, Fubo carries the biggest sporting events of the year like the Super Bowl, World Series, NBA Finals, Stanley Cup Playoffs and the Olympics, as well as coverage of every golf major, tennis major and Triple Crown race.`,
  },

  {
    question: 'What soccer leagues can I watch with Fubo?',
    answer: `With Fubo, you can watch more live soccer than on any other streaming service. Enjoy the English Premier League, Liga MX, LaLiga, MLS, German Bundesliga and much more. Plus the FIFA World Cup and Qualifiers, UEFA Champions League and Europa League, French Ligue 1, Italian Serie A, La Liga, Primeira League, Turkish Super Lig, Ascenso MX, Brasilierao, Colombia Copa Mustang, Copa America, Ecuador Primera A, Uruguay Primera Division, Venezuela Primera Division, NCAA college soccer and more.`,
  },
  {
    question: 'Does Fubo have my local news and weather channels?',
    //REMOVED: univision_removed
    // answer: `Yes! You can watch your local news and weather coverage with Fubo. We have your local stations from ABC, CBS, FOX and NBC more, including The CW, Telemundo and Univision. For news junkies, we also offer over 10 news channels in our lowest-cost plan, and more you can add on. Check the channel finder above to see all of the channels available in your area.`,
    //REPLACED: univision_removed
    answer: `Yes! You can watch your local news and weather coverage with Fubo. We have your local stations from ABC, CBS, FOX and NBC more, including The CW and Telemundo. For news junkies, we also offer over 10 news channels in our lowest-cost plan, and more you can add on. Check the channel finder above to see all of the channels available in your area.`,
  },
  {
    question: 'Does Fubo have my favorite shows?',
    answer:
      'Of course! We work hard to bring you the best channels with the best shows. You can watch The Bear, Tracker, FBI, Chicago Fire, Chicago Med, Chicago PD, 60 Minutes, Ghosts, The Voice, Law & Order: SVU, 9-1-1, NCIS, Survivor, The Price Is Right, Jeopardy!, The Bachelor, Dancing with the Stars, SportsCenter, First Take, Real Housewives, Bluey, Cocomelon, Paw Patrol, Family Guy, Bob’s Burgers, Criminal Minds and much more. Plus, we have over 40,000 on-demand TV shows and movies for you to binge each month!',
  },
  {
    question: 'Does Fubo have DVR?',
    answer:
      'Yes, every Fubo plan includes unlimited Cloud DVR space at no extra charge. That means you can record all your favorite teams, shows, movies and more. Since our DVR is cloud-based, that means you can access all of your DVR recordings from all of your devices — with no extra equipment or installation required. So if you hit record on your smart TV, you can watch the recording on your phone.',
  },
  {
    question: 'How many devices can I watch Fubo on at once?',
    answer:
      'Most Fubo plans allow you to stream on up to 10 devices at once when on your home internet connection. So some people watch on their phones, others can watch on their computers, and you can watch on your big-screen smart TV — all at the same time. Plus, you can stream on up to three devices at once when on the go. With our Latino plan, you can watch on up to two devices at the same time.',
  },
  {
    question: 'Which devices can I stream from with Fubo?',
    answer: (
      <>
        {' '}
        With Fubo, you can stream on all popular connected TVs, smart phones and computers.
        Including Apple TV, Roku, Samsung Smart TV and Amazon Fire TV; Mac and PC computers; Apple
        and Android phones and tablets; and more. View the list of all supported devices & browsers{' '}
        <a
          href="https://support.fubo.tv/hc/en-us/articles/7120445443085-What-supported-devices-can-I-watch-Fubo-on"
          target="_blank"
        >
          here
        </a>
        .
      </>
    ),
  },
]

export const svodFAQs = {
  us: {
    en: [
      {
        question: 'What is the Fubo Free plan?',
        answer:
          'The Fubo Free plan is a way for you to stream live TV for free, without cable. Just create an account and start watching. For an additional cost, you can also add premium channels to your Fubo Free plan.',
      },
      {
        question: 'What’s included with the Fubo Free plan?',
        answer:
          'The Fubo Free plan includes 170+ live channels of live shows, movies, news and more. It also includes a full library of on-demand movies and shows for you to binge whenever you want.',
      },
      {
        question: 'Can I watch the Fubo Free plan on my phone and other devices?',
        answer:
          'Yes. Simply download the Fubo app for your phone, smart TV and more, log in, and start streaming.',
      },
      {
        question: 'Can I add premium channels to the Fubo Free plan?',
        answer:
          'Yes, you can add premium channels to your Fubo Free plan for an additional cost, including Paramount+ with SHOWTIME®. More free channels and premium channels are being added all the time, so you can create your perfect, low-cost live TV package.',
      },
      {
        question: 'Can I record shows with the Fubo Free plan?',
        answer:
          'At this time, the Fubo Free plan does not include Cloud DVR. If you want a plan that has Cloud DVR, you can upgrade to a Fubo paid plan.',
      },
      {
        question:
          'If I move from a Fubo paid plan to the Fubo Free plan, will I be able to keep my Cloud DVR recordings?',
        answer:
          'If you cancel your Fubo paid plan and move to the Fubo Free plan, you will no longer be able to access your Cloud DVR recordings.',
      },
      {
        question: 'Does the Fubo Free plan include my local or regional channels?',
        answer:
          'At this time, the Fubo Free plan does not include local channels or regional sports networks. However, both are available in our Fubo paid plans.',
      },
      {
        question: 'How do I upgrade from the Fubo Free plan to a Fubo paid plan?',
        answer:
          'Just log in to your Fubo Free account at fubo.tv, and click the gear icon at the top of the screen. Then, select My Account. From there, select “Reactivate your subscription” and follow the instructions to reactivate your Fubo paid plan.',
      },
    ],
  },
}

export const fanduelSportsNetworkFAQs = [
  {
    question: 'Is FanDuel Sports Network the new name for Bally Sports?',
    answer:
      'Yes, Bally Sports has rebranded to FanDuel Sports Network. Fubo subscribers who previously had Bally Sports in their channel lineup can now find their local sports teams on FanDuel Sports Network.',
  },
  {
    question: 'What happened to Bally Sports on Fubo?',
    answer:
      'The company that owns Bally Sports partnered with FanDuel to rebranded the regional sports network as FanDuel Sports Network. Fubo subscribers can still access teams and sports previously available on Bally Sports under this new name.',
  },
  {
    question:
      'Will I still be able to watch my local teams on FanDuel Sports Network through Fubo?',
    answer:
      'Yes, FanDuel Sports Network will continue to broadcast local teams and games as Bally Sports did. Fubo subscribers can still enjoy the local team coverage on the new FanDuel Sports Network channel.',
  },
  {
    question:
      'Do I need a new subscription with Fubo to watch FanDuel Sports Network if I was a Bally Sports viewer?',
    answer:
      "No, if you were already watching Bally Sports on Fubo, you don't need a new subscription. The Bally Sports channels have simply been rebranded to FanDuel Sports Network, and you'll still be able to access your local team coverage.",
  },
  {
    question: 'How can I watch FanDuel Sports Network on Fubo?',
    answer:
      'FanDuel Sports Network is available through your Fubo subscription in place of Bally Sports. Check your channel lineup on Fubo to find FanDuel Sports Network or use the Fubo app to stream games.',
  },
  {
    question:
      'Will FanDuel Sports Network on Fubo show all the same sports and teams as Bally Sports?',
    answer:
      'Fubo will continue to carry the sports and teams that were carried by Bally Sports, just on the rebranded FanDuel Sports Network.',
  },
  {
    question: 'Can I still stream FanDuel Sports Network on the Fubo app?',
    answer:
      'Yes, Fubo subscribers can stream FanDuel Sports Network directly through the Fubo app, just as they did with Bally Sports.',
  },
  {
    question: 'Why did Bally Sports change to FanDuel Sports Network on Fubo?',
    answer:
      "The change is due to Bally Sports's parent company striking a partnership with FanDuel. Fubo customers can still watch their favorite local sports teams and events; only the network's name has changed.",
  },
]
