import React from 'react'
import { useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectCtaCopyContinueFlag } from 'src/modules/reducers/cohorts'

export default props => {
  const isCtaCopyContinueEnabled = useSelector(selectCtaCopyContinueFlag)
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream & DVR Live Sports"
      subtitle="CATCH YOUR GAMES ON YOUR TV, PHONE, TABLET & MORE."
      tagline="Anytime. Anywhere."
      networkSet="sports"
      showRedzone={false}
      ctaText={isCtaCopyContinueEnabled ? 'Continue' : null}
    />
  )
}
