import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'
export default props => (
  <StyledContainer>
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      // video={videos.us_open.videoUrl}
      // videoMobile={videos.us_open.videoMobileUrl}
      poster={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/US-Open-poster.jpg'}
      title={
        <>
          Watch the U.S. Open
          <div className="desktop-line-break"> on ESPN</div>
        </>
      }
      pageTitle={'Watch the U.S. Open on ESPN'}
      // subtitle="PLUS OVER 100 CHANNELS OF LIVE SPORTS & TV"
      tagline="Stream live matches from any device with Fubo"
      networkSet="sports"
      ctaText={'Start Watching'}
      sportId={sportIds.Tennis}
      leagueId={leagueIds['US Open']}
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline;
    @media (min-width: 768px) {
      display: block;
    }
  }

  .content-container .hero-content {
    max-width: 710px;
  }
`
