import React, { useEffect, useState } from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { caPlanPricing } from 'src/constants/caPlanData'
import { images } from 'src/constants/images'
import { videos } from 'src/constants/videos'
import { useEPLImage } from 'src/modules/hooks/useEPLImage'
import styled from 'styled-components'

export default props => {
  const posterImg = useEPLImage()

  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        offerBanner="✔ Enter the code from Mike's Hard to claim your FREE 1-year subscription"
        hasOfferInput={true}
        ctaText="Redeem Offer"
        poster={images.corus.url}
        noVideo={true}
        pageTitle=" Watch live Premier League & international soccer"
        title={
          <>
            Watch live
            <div className="desktop-line-break">sports, news &</div>
            entertainment
          </>
        }
        subtitle="Unlock your FREE 1-year subscription now!"
        // tagline="Redeem now and enjoy your free Fubo subscription! Cancel anytime."
        hasPlanCards={false}
        plan="soccer"
        networkSet="canada"
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-mikes-hard-lemonade.png?ch=width&auto=format,compress"
        logoHeight="60px"
        heroCtaNote={
          <>
            All subscribers are required to register. Once registered you acknowledge that you have
            read and agree to Fubo's{' '}
            <a
              href="https://legal.fubo.tv/policies/en-CA/?name=terms-of-service"
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              Terms of Service
            </a>
            , and that you will be charged C$0 today. <strong>After</strong> 12 months you will be
            automatically charged C${caPlanPricing.premium.totalPrice.annual} for another year,
            unless you choose to cancel.{' '}
            <strong>
              You can cancel through your Account Page at any time before renewal without charge.
            </strong>
          </>
        }
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 800px;
  }
  .desktop-line-break {
    // display: inline;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
