import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
import { ratePlanCodes } from 'src/constants'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="latino"
    callsign="BEIN1HD"
    title="Watch beIN Sports Live with Fubo"
    offerCode={ratePlanCodes.latino}
    networkSet="entertainmentLatino"
    networkName="beIN Sports"
  />
)
