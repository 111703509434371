import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { promoCodes } from 'src/constants/promoCodes'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      offerBanner={
        <>
          ✔ Special Offer Applied! <br />
          Enjoy 30% off 3 months of our Elite plan
        </>
      }
      offerCode={promoCodes.lenovo}
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      tagline="Watch and DVR the channels you love. No contracts, no commitment."
    />
  )
}
