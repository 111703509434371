import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        poster={`https://assets-imgx.fubo.tv/marketing/lp/images/posters/CBS-Evergreen_web-v2.jpg`}
        posterMobile={`https://assets-imgx.fubo.tv/marketing/lp/images/posters/CBS-Evergreen_mobile-v2.jpg`}
        title="Stream CBS with Fubo"
        subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
        tagline="Watch and DVR the channels you love. No contracts, no commitment."
        showRedzone={false}
        customNetworks={['cbs', 'abc', 'fox', 'nbc', 'espn', 'fx', 'fs1']}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div``
