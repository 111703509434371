import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { promoCodes } from 'src/constants/promoCodes'
export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      offerBanner={
        <>
          ✔ Service members enjoy a special offer through GOVX <br />
          Get $20 off 2 months ($10/mo.) of Fubo.
        </>
      }
      offerCode={promoCodes.govX}
      title={'Stream Live TV & Sports'}
      subtitle={'Stream your favorite teams, shows & more from any device.'}
      tagline="No contracts, no commitment."
      showRSN={true}
    />
  )
}
