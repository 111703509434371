import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { promoCodes } from 'src/constants/promoCodes'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={"https://assets-imgx.fubo.tv/marketing/lp/videos/compressed/ColinCowherd_LP_16x9_11s.mp4"}
        videoMobile={"https://assets-imgx.fubo.tv/marketing/lp/videos/mobile/ColinCowherd_LP_1x1_11s.mp4"}
        title="Stream Live TV & Sports"
        tagline="Watch and DVR the channels you love. No contracts, no commitment."
        showRedzone={false}
        newTemplate={true}
        offerBanner={
          <>
            ✔ Special offer applied <br /> Save $50 on your first 2 months
          </>
        }
        offerCode={promoCodes.colin_cowherd}
        logo={'https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-colin-cowherd.png'}
        logoHeight={'80px'}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-content .logo-wrapper{
      margin-bottom: 130px;
   
  }

  .hero-new .hero-content {
 
    h1 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
        font-size: 68px;
      }
    }
    h2 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
  }
`
